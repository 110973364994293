.btn,
%btn {
	margin: $btn-margin;
	border: $btn-border;
	border-radius: $btn-radius;
	padding: $btn-padding;
	font-family: $btn-font;
	font-size: $btn-font-size;
	font-weight: $btn-font-weight;
}

.btn-default,
%btn-default {
	border-color: $btn-default-border;
	background-color: $btn-default-bg;
	color: $btn-default-color;
}

.btn-default:hover,
%btn-default:hover {
	border-color: $btn-default-border-hover;
	background-color: $btn-default-hover;
	color: $btn-default-text-hover;
}

.btn-primary,
%btn-primary {
	border-color: $btn-primary-border;
	background-color: $btn-primary-bg;
	color: $btn-primary-color;
}

.btn-primary:hover,
%btn-primary:hover {
	border-color: $btn-primary-border-hover;
	background-color: $btn-primary-hover;
	color: $btn-primary-text-hover;
}
