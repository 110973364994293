//
// Mixins
// --------------------------------------------------


// Utilities
// -------------------------

/*
 *	Clearfix
 *	Source: http://nicolasgallagher.com/micro-clearfix-hack/
 *
 * 	For modern browsers
 *	1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 *  2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

@mixin clearfix() {
	&::before,
	&::after {
		display: table; // 2
		content: ' '; // 1
	}
	&::after {
		clear: both;
	}
}

/*
 *	Calc()
 *
 *  Support for all browsers and optional fallback for very old browsers.
 */

@mixin calc($property, $expression, $fallback: null) {
	@if $fallback {
		#{$property}: #{$fallback};
	}
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: -o-calc(#{$expression}); 
	#{$property}: calc(#{$expression}); 
}

// WebKit-style focus
@mixin tab-focus() {
	// Default
	outline: thin dotted;
	// WebKit
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
	width: $width;
	height: $height;
}
@mixin square($size) {
	@include size($size, $size);
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
	&::-moz-placeholder           { color: $color;   // Firefox
																	opacity: 1; } // See https://github.com/twbs/bootstrap/pull/11526
	&:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
	&::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// CSS image replacement
//
// Heads up! v3 launched with with only `.hide-text()`, but per our pattern for
// mixins being reused as classes with the same name, this doesn't hold up. As
// of v3.0.1 we have added `.text-hide()` and deprecated `.hide-text()`. Note
// that we cannot chain the mixins together in Less, so they are repeated.
//
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757

// Deprecated as of v3.0.1 (will be removed in v4)
@mixin hide-text() {
	font: #{0/0} a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
// New mixin to use as of v3.0.1
@mixin text-hide() {
	@include hide-text();
}



// CSS3 PROPERTIES
// --------------------------------------------------

// Single side border-radius
@mixin border-top-radius($radius) {
	border-top-right-radius: $radius;
	 border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
	border-bottom-right-radius: $radius;
		 border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
	border-bottom-right-radius: $radius;
	 border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
	border-bottom-left-radius: $radius;
		 border-top-left-radius: $radius;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
//   supported browsers that have box shadow capabilities now support the
//   standard `box-shadow` property.
@mixin box-shadow($shadow...) {
	-webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
					box-shadow: $shadow;
}

/*
 *	Transform 
 */

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}
@mixin scale($scale) {
	@include transform(scale($scale));
}
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin translate3d($x, $y, $z) {
	@include transform(translate3d($x, $y, $z));
}
@mixin rotateX($degrees) {
	@include transform(rotateX($degrees));
}
@mixin rotateY($degrees) {
	@include transform(rotateY($degrees));
}
@mixin transition($transition...) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}
@mixin transition-property($transition-property...) {
	-webkit-transition-property: $transition-property;
	-moz-transition-property: $transition-property;
	-ms-transition-property: $transition-property;
	-o-transition-property: $transition-property;
	transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	-moz-transition-delay: $transition-delay;
	-ms-transition-delay: $transition-delay;
	-o-transition-delay: $transition-delay;
	transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
	-webkit-transition-duration: $transition-duration;
	-moz-transition-duration: $transition-duration;
	-ms-transition-duration: $transition-duration;
	-o-transition-duration: $transition-duration;
	transition-duration: $transition-duration;
}
@mixin transition-transform($transition...) {
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-ms-transition: transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}
@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	perspective: $perspective;
}
@mixin perspective-origin($perspective) {
	-webkit-perspective-origin: $perspective;
	perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}
@mixin transform-style($style) {
	-webkit-transform-style: $style;
	transform-style: $style;
}

// Animations
@mixin animation($animation) {
	-webkit-animation: $animation;
					animation: $animation;
}
@mixin animation-name($name) {
	-webkit-animation-name: $name;
					animation-name: $name;
}
@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
					animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
	-webkit-animation-timing-function: $timing-function;
					animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
	-webkit-animation-delay: $delay;
					animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
	-webkit-animation-iteration-count: $iteration-count;
					animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
	-webkit-animation-direction: $direction;
					animation-direction: $direction;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
@mixin backface-visibility($visibility){
	-webkit-backface-visibility: $visibility;
		 -moz-backface-visibility: $visibility;
					backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
	-webkit-box-sizing: $boxmodel;
		 -moz-box-sizing: $boxmodel;
					box-sizing: $boxmodel;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
	-webkit-user-select: $select;
		 -moz-user-select: $select;
			-ms-user-select: $select; // IE10+
					user-select: $select;
}

// Resize anything
@mixin resizable($direction) {
	resize: $direction; // Options: horizontal, vertical, both
	overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
	-webkit-column-count: $column-count;
		 -moz-column-count: $column-count;
					column-count: $column-count;
	-webkit-column-gap: $column-gap;
		 -moz-column-gap: $column-gap;
					column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
	word-wrap: break-word;
	-webkit-hyphens: $mode;
		 -moz-hyphens: $mode;
			-ms-hyphens: $mode; // IE10+
			 -o-hyphens: $mode;
					hyphens: $mode;
}

// Opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	// IE8 filter
	$opacity-ie: ($opacity * 100);
	filter: #{alpha(opacity=$opacity-ie)};
}



// GRADIENTS
// --------------------------------------------------



// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(left, color-stop($start-color $start-percent), color-stop($end-color $end-percent)); // Safari 5.1-6, Chrome 10+
	background-image:  linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
	background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
	background-repeat: repeat-x;
	background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
	background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
	background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
	background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
	background-repeat: no-repeat;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
	background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-repeat: no-repeat;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
	background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
	background-image: radial-gradient(circle, $inner-color, $outer-color);
	background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
	background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

// Reset filters for IE
//
// When you need to remove a gradient background, do not forget to use this to reset
// the IE filter for IE9 and below.
@mixin reset-filter() {
	filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}



// Retina images
//
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
	background-image: url(if($bootstrap-sass-asset-helper, twbs-image-path("#{$file-1x}"), "#{$file-1x}"));

	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		background-image: url(if($bootstrap-sass-asset-helper, twbs-image-path("#{$file-2x}"), "#{$file-2x}"));
		background-size: $width-1x $height-1x;
	}
}


// Responsive image
//
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
	display: $display;
	max-width: 100%; // Part 1: Set a maximum relative to the parent
	height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}


// COMPONENT MIXINS
// --------------------------------------------------

// Horizontal dividers
// -------------------------
// Dividers (basically an hr) within dropdowns and nav lists
@mixin nav-divider($color: #e5e5e5) {
	height: 1px;
	margin: (($line-height-computed / 2) - 1) 0;
	overflow: hidden;
	background-color: $color;
}

// Panels
// -------------------------
@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
	border-color: $border;

	& > .panel-heading {
		color: $heading-text-color;
		background-color: $heading-bg-color;
		border-color: $heading-border;

		+ .panel-collapse .panel-body {
			border-top-color: $border;
		}
	}
	& > .panel-footer {
		+ .panel-collapse .panel-body {
			border-bottom-color: $border;
		}
	}
}

// Alerts
// -------------------------
@mixin alert-variant($background, $border, $text-color) {
	background-color: $background;
	border-color: $border;
	color: $text-color;

	hr {
		border-top-color: darken($border, 5%);
	}
	.alert-link {
		color: darken($text-color, 10%);
	}
}

// Tables
// -------------------------
@mixin table-row-variant($state, $background) {
	// Exact selectors below required to override `.table-striped` and prevent
	// inheritance to nested tables.
	.table > thead > tr,
	.table > tbody > tr,
	.table > tfoot > tr {
		> td.#{$state},
		> th.#{$state},
		&.#{$state} > td,
		&.#{$state} > th {
			background-color: $background;
		}
	}

	// Hover states for `.table-hover`
	// Note: this is not available for cells or rows within `thead` or `tfoot`.
	.table-hover > tbody > tr {
		> td.#{$state}:hover,
		> th.#{$state}:hover,
		&.#{$state}:hover > td,
		&.#{$state}:hover > th {
			background-color: darken($background, 5%);
		}
	}
}

/*
 *	Lists
 */

@mixin listitems($style, $left, $header, $footer, $offcanvas) {
	@if ($style == none) {
		ul li {
			background: url('../graphics/list-bullet.png') no-repeat center left;
			padding-left: $left;
		}
		@if ($header == false) {
			header ul li {
				padding-left: 0;
				background-image: none;
			}
		}
		@if ($footer == false) {
			footer ul li {
				padding-left: 0;
				background-image: none;
			}
		}
		@if ($offcanvas == false) {
			.efelle-offcanvas ul li {
				padding-left: 0;
				background-image: none;
			}
		}
	} @else {
		@if ($header == false) {
			header ul li {
				padding-left: 0;
				list-style-type: none;
			}
		}
		@if ($footer == false) {
			footer ul li {
				padding-left: 0;
				list-style-type: none;
			}
		}
		@if ($offcanvas == false) {
			.efelle-offcanvas ul li {
				padding-left: 0;
				list-style-type: none;
			}
		}
	}
	ul li {
		list-style-type: $style;
	}
}
@mixin orderedlistcolor($is, $color) {
	@if ($is == true) {
		ol {
			margin: 15px 0; /* Remove the default left margin */
			padding-left: 0; /* Remove the default left padding */
			counter-reset: li; /* Initiate a counter */
		}
		ol > li {
			position: relative; /* Create a positioning context */
			margin: 8px 0 0;
			padding: 0; /* Add some spacing around the content */
			list-style: none; /* Disable the normal item numbering */
		}
		ol > li:before {
			padding: 0 13px 0 0;
			color: $color;
			font-weight: bold;
			content: counter(li)'.'; /* Use the counter as content */
			counter-increment: li; /* Increment the counter by 1 */
		}
	}
}

// List Groups
// -------------------------
@mixin list-group-item-variant($state, $background, $color) {
	.list-group-item-#{$state} {
		color: $color;
		background-color: $background;

		// [converter] extracted a& to a.list-group-item-#{$state}
	}

	a.list-group-item-#{$state} {
		color: $color;

		.list-group-item-heading { color: inherit; }

		&:hover,
		&:focus {
			color: $color;
			background-color: darken($background, 5%);
		}
		&.active,
		&.active:hover,
		&.active:focus {
			color: #fff;
			background-color: $color;
			border-color: $color;
		}
	}
}

// Button variants
// -------------------------
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border) {
	color: $color;
	background-color: $background;
	border-color: $border;

	&:hover,
	&:focus,
	&:active,
	&.active {
		color: $color;
		background-color: darken($background, 8%);
				border-color: darken($border, 12%);
	}
	.open & { &.dropdown-toggle {
		color: $color;
		background-color: darken($background, 8%);
				border-color: darken($border, 12%);
	} }
	&:active,
	&.active {
		background-image: none;
	}
	.open & { &.dropdown-toggle {
		background-image: none;
	} }
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: $background;
					border-color: $border;
		}
	}

	.badge {
		color: $background;
		background-color: $color;
	}
}

// Button sizes
// -------------------------
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
	padding: $padding-vertical $padding-horizontal;
	font-size: $font-size;
	line-height: $line-height;
	border-radius: $border-radius;
}

// Pagination
// -------------------------
@mixin pagination-size($padding-vertical, $padding-horizontal, $font-size, $border-radius) {
	> li {
		> a,
		> span {
			padding: $padding-vertical $padding-horizontal;
			font-size: $font-size;
		}
		&:first-child {
			> a,
			> span {
				@include border-left-radius($border-radius);
			}
		}
		&:last-child {
			> a,
			> span {
				@include border-right-radius($border-radius);
			}
		}
	}
}

// Labels
// -------------------------
@mixin label-variant($color) {
	background-color: $color;
	&[href] {
		&:hover,
		&:focus {
			background-color: darken($color, 10%);
		}
	}
}

// Contextual backgrounds
// -------------------------
// [converter] $parent hack
@mixin bg-variant($parent, $color) {
	#{$parent} {
		background-color: $color;
	}
	a#{$parent}:hover {
		background-color: darken($color, 10%);
	}
}

// Typography
// -------------------------
// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
	#{$parent} {
		color: $color;
	}
	a#{$parent}:hover {
		color: darken($color, 10%);
	}
}

// Navbar vertical align
// -------------------------
// Vertically center elements in the navbar.
// Example: an element has a height of 30px, so write out `.navbar-vertical-align(30px);` to calculate the appropriate top margin.
@mixin navbar-vertical-align($element-height) {
	margin-top: (($navbar-height - $element-height) / 2);
	margin-bottom: (($navbar-height - $element-height) / 2);
}

// Progress bars
// -------------------------
@mixin progress-bar-variant($color) {
	background-color: $color;
	.progress-striped & {
		@include gradient-striped();
	}
}

// Responsive utilities
// -------------------------
// More easily include all the states for responsive-utilities.less.
// [converter] $parent hack
@mixin responsive-visibility($parent) {
	#{$parent} {
		display: block !important;
	}
	table#{$parent}  { display: table; }
	tr#{$parent}     { display: table-row !important; }
	th#{$parent},
	td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
	#{$parent} {
		display: none !important;
	}
}


// Grid System
// -----------

// Centered container element
@mixin container-fixed() {
	margin-right: auto;
	margin-left: auto;
	padding-left:  ($grid-gutter-width / 2);
	padding-right: ($grid-gutter-width / 2);
	@include clearfix();
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
	margin-left:  ($gutter / -2);
	margin-right: ($gutter / -2);
	@include clearfix();
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	float: left;
	width: percentage(($columns / $grid-columns));
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
	@media (min-width: $screen-xs-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xs-column-push($columns) {
	@media (min-width: $screen-xs-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-xs-column-pull($columns) {
	@media (min-width: $screen-xs-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-sm-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-sm-column-offset($columns) {
	@media (min-width: $screen-sm-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-sm-column-push($columns) {
	@media (min-width: $screen-sm-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-sm-column-pull($columns) {
	@media (min-width: $screen-sm-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-md-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-md-column-offset($columns) {
	@media (min-width: $screen-md-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-md-column-push($columns) {
	@media (min-width: $screen-md-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-md-column-pull($columns) {
	@media (min-width: $screen-md-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-lg-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}
@mixin make-lg-column-offset($columns) {
	@media (min-width: $screen-lg-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}
@mixin make-lg-column-push($columns) {
	@media (min-width: $screen-lg-min) {
		left: percentage(($columns / $grid-columns));
	}
}
@mixin make-lg-column-pull($columns) {
	@media (min-width: $screen-lg-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns() {
	$list: '';
	$i: 1;
	$list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
	}
	#{$list} {
		position: relative;
		// Prevent columns from collapsing when empty
		min-height: 1px;
		// Inner gutter via padding
		padding-left:  ($grid-gutter-width / 2);
		padding-right: ($grid-gutter-width / 2);
	}
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class) {
	$list: '';
	$i: 1;
	$list: ".col-#{$class}-#{$i}";
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-#{$class}-#{$i}";
	}
	#{$list} {
		float: left;
	}
}


@mixin calc-grid-column($index, $class, $type) {
	@if ($type == width) and ($index > 0) {
		.col-#{$class}-#{$index} {
			width: percentage(($index / $grid-columns));
		}
	}
	@if ($type == push) {
		.col-#{$class}-push-#{$index} {
			left: percentage(($index / $grid-columns));
		}
	}
	@if ($type == pull) {
		.col-#{$class}-pull-#{$index} {
			right: percentage(($index / $grid-columns));
		}
	}
	@if ($type == offset) {
		.col-#{$class}-offset-#{$index} {
			margin-left: percentage(($index / $grid-columns));
		}
	}
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}


// Create grid for specific class
@mixin make-grid($class) {
	@include float-grid-columns($class);
	@include loop-grid-columns($grid-columns, $class, width);
	@include loop-grid-columns($grid-columns, $class, pull);
	@include loop-grid-columns($grid-columns, $class, push);
	@include loop-grid-columns($grid-columns, $class, offset);
}

// Form validation states
//
// Used in forms.less to generate the form validation CSS for warnings, errors,
// and successes.

@mixin form-control-validation($text-color: #555, $border-color: #ccc, $background-color: #f5f5f5) {
	// Color the label and help text
	.help-block,
	.control-label,
	.radio,
	.checkbox,
	.radio-inline,
	.checkbox-inline  {
		color: $text-color;
	}
	// Set the border and box shadow on specific inputs to match
	.form-control {
		border-color: $border-color;
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075)); // Redeclare so transitions work
		&:focus {
			border-color: darken($border-color, 10%);
			$shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($border-color, 20%);
			@include box-shadow($shadow);
		}
	}
	// Set validation states also for addons
	.input-group-addon {
		color: $text-color;
		border-color: $border-color;
		background-color: $background-color;
	}
	// Optional feedback icon
	.form-control-feedback {
		color: $text-color;
	}
}

// Form control focus state
//
// Generate a customized focus state and for any input with the specified color,
// which defaults to the `$input-focus-border` variable.
//
// We highly encourage you to not customize the default value, but instead use
// this to tweak colors on an as-needed basis. This aesthetic change is based on
// WebKit's default styles, but applicable to a wider range of browsers. Its
// usability and accessibility should be taken into account with any change.
//
// Example usage: change the default blue border and shadow to white for better
// contrast against a dark gray background.

@mixin form-control-focus($color: $input-border-focus) {
	$color-rgba: rgba(red($color), green($color), blue($color), .6);
	&:focus {
		border-color: $color;
		outline: 0;
		@include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
	}
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

// [converter] $parent hack
@mixin input-size($parent, $input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
	#{$parent} {
		height: $input-height;
		padding: $padding-vertical $padding-horizontal;
		font-size: $font-size;
		line-height: $line-height;
		border-radius: $border-radius;
	}

	select#{$parent} {
		height: $input-height;
		line-height: $input-height;
	}

	textarea#{$parent},
	select[multiple]#{$parent} {
		height: auto;
	}
}
