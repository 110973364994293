.tip-darkgray {
	opacity:0.95;
	z-index:1000;
	text-align:left;
	text-shadow:#444 0 1px 1px;
	border:1px solid #888;
	padding:8px;
	min-width:50px;
	max-width:530px;
	color:#fff;
	background-color:#999;
	background-image:url(tip-darkgray.png); /* bgImageFrameSize >= 11 should work fine */
	/**
	 * - If you set a background-image, border/padding/background-color will be ingnored.
	 *   You can set any padding to .tip-inner instead if you need.
	 * - If you want a tiled background-image and border/padding for the tip,
	 *   set the background-image to .tip-inner instead.
	 */
}
.tip-darkgray .tip-inner {
	font:bold 12px/18px arial,helvetica,sans-serif;
	margin-top:-1px;
	padding:0 4px 3px 4px;
}

/* Configure an arrow image - the script will automatically position it on the correct side of the tip */
.tip-darkgray .tip-arrow-top {
	margin-top:-7px;
	margin-left:15px;
	top:0;
	left:0;
	width:16px;
	height:10px;
	background:url(tip-darkgray_arrows.png) no-repeat;
}
.tip-darkgray .tip-arrow-right {
	margin-top:-9px; /* approx. half the height to center it */
	margin-left:-7px;
	top:50%;
	left:100%;
	width:11px;
	height:21px;
	background:url(tip-darkgray_arrows.png) no-repeat -22px 0;
}
.tip-darkgray .tip-arrow-bottom {
	margin-top:-7px;
	margin-left:15px;
	top:100%;
	left:0;
	width:22px;
	height:13px;
	background:url(tip-darkgray_arrows.png) no-repeat -44px 0;
}
.tip-darkgray .tip-arrow-left {
	margin-top:-9px; /* approx. half the height to center it */
	margin-left:-6px;
	top:50%;
	left:0;
	width:11px;
	height:21px;
	background:url(tip-darkgray_arrows.png) no-repeat -66px 0;
}