// mixins

/* ------ Manda's Mixins ------- */

// Absolute Positioning
@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
}

// Create responsive grid - 3 per row default. options up to 5 per row.
// Use skip to skip a certain breakpoint
@mixin grid($tile-width: 300px, $tile-height: auto, $tile-margin: 30px, $grid-padding: 15px, $row: 3, $skip-md: null, $skip-lg: null) {
    // Do the Maths
    $lg-tile-container: ($tile-width * 3) + ($tile-margin * 6);
    $md-tile-container: ($tile-width * 2) + ($tile-margin * 4);
    $sm-tile-container: $tile-width + ($tile-margin * 2);
    @if $row == 5 {
        $xxl-tile-container: ($tile-width * 5) + ($tile-margin * 10);
        $xxl-breakpoint: $xxl-tile-container + ($grid-padding * 2);
    }
    @if $row == 4 {
        $xl-tile-container: ($tile-width * 4) + ($tile-margin * 8);
        $xl-breakpoint: $xl-tile-container + ($grid-padding * 2);
    }
    @if $row == 2 {
        $lg-tile-container: ($tile-width * 2) + ($tile-margin * 4);
    }
    $lg-breakpoint: $lg-tile-container + ($grid-padding * 2);
    $md-breakpoint: $md-tile-container + ($grid-padding * 2);
    $sm-breakpoint: $sm-tile-container + ($grid-padding * 2);
    .grid {
        @extend %clearfix;
        margin: 0 auto;
        max-width: $tile-width;
        padding: 0 $grid-padding;
        width: 100%;
        @media (min-width: $sm-breakpoint) { 
            width: $sm-tile-container; 
            max-width: 100%;
            padding: 0;
        }
        @if $skip-md == null {
            @media (min-width: $md-breakpoint) { width: $md-tile-container; }
        } else {
            @media (min-width: $md-breakpoint) { width: $sm-tile-container; }
        }
        @if $skip-lg == null {
            @media (min-width: $lg-breakpoint) { width: $lg-tile-container; }
        } else {
            @media (min-width: $lg-breakpoint) { width: $md-tile-container; }
        }
        @if $row == 4  {
             @media (min-width: $xl-breakpoint) { width: $xl-tile-container; }
        }
        @if $row == 5  {
             @media (min-width: $xxl-breakpoint) { width: $xxl-tile-container; }
        }
        .tile  {
            width: 100%;
            margin: $tile-margin 0;
            max-width: $tile-width;
            position: relative;
            vertical-align: top;
            @media (min-width: $sm-breakpoint) {
                float: left;
                margin: $tile-margin;
                padding: 0;
                width: $tile-width;
            }
            @media (max-width: $sm-breakpoint) {
                width: 100%;
                margin: $tile-margin 0;
            }
        }
    }
}

/* ------ JBC's Mixins ------- */

