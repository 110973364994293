/* SUBPAGE */

.bct {
	@extend %wrapper;
	ul {
		margin: 0;
		padding: 0;
		@media(min-width: $screen-xs) {
			margin: 20px 0 10px;
		}
		li {
			font-size: 12px;
			margin:0;
			padding: 0;
			&:before {
				padding: 0 5px;
			}
			@media(max-width: $screen-xs) {
				display: block;
				&:before {
					padding: 0 5px 0 0;
				}
			}

		}
	}
}

.subpage-content {
	@extend %wrapper;
	text-align: center;
	margin: 63px auto;
	max-width: 770px;
	h4 {
		margin-bottom: 0;
	}
	h1 {
		margin: 0;
	}
	hr {
		margin-top: 16px;
		margin-bottom: 13px;
	}
	p {
		line-height: 1.35em;
	}
}

.subpage-wrapper {
	@extend %wrapper;
	margin: 63px auto;
	max-width: 1000px;
	p {
		a {
			&:before {
				display: block; 
				content: " "; 
				visibility: hidden;
				margin-top: -50px; 
				height: 50px; 
				@media (min-width: $screen-xs) {
					margin-top: -70px; 
					height: 70px;
				}
			}
		}
	}
}

.subpage-full {
	@extend %wrapper;
	margin: 44px auto;
}

/* Our Process Custom Subpage */
.process-spots {
	@extend %wrapper;
	max-width: 1370px;
	padding: 0 10px;
	.process-row {
		@extend %clearfix;
		margin: 60px 0;
		.process-caption,
		.process-img {
			@media(min-width: $screen-md) {
				width: 50%;
				float: left;
			}
		}
		.process-caption {
			padding: 15px;
			float: right;
			@media(min-width: $screen-md) {
				padding: 30px;
			}
			@media(min-width: $screen-lg) {
				padding: 60px 130px;
			}
		}
		.process-img {
			img {
				@media(max-width: $screen-md) {
					margin: 0 auto;
				}
			}
		}
		&:nth-child(odd) {
			.process-caption {
				@media(min-width: $screen-md) {
					float: left;
				}
			}
			.process-img {
				@media(min-width: $screen-md) {
					float: right;
				}
			}
		}
		h2 {
			margin: 0 0 18px;
		}
		hr {
			margin: 0 0 13px;
		}
		p {
			line-height: 1.35em;
		}
	}
}
.process-testimonial {
	@extend %wrapper;
	position: relative;
	.process-test-img {

	}
	.process-test-caption {
		position: absolute;
		top: 62%;
		right: 0;
		left: 0;
		padding: 0 10%;
		text-align: center;
		* {
			color: $light;
		}
		h4 {
			font-size: 52px;
			line-height: 0.95em;
			margin: 0 0 16px;
		}
	}
}

.prod-share {
	padding-top: 12px;
	@media(max-width: $screen-md) {
		clear: both;
	}
	@media(min-width: $screen-md) {
		float: right;
		width: 83%;
	}
	> span {
		line-height: 24px;
		display: inline-block;
		float: left;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 0.05em;
		margin-right: 10px;
		color: $border-gray;
	}
	> div {
		display: inline-block;
	}
	.at-style-responsive .at-share-btn {
		padding: 0;
	}
	.at-resp-share-element .at-share-btn {
		margin: 0 7px 0 0;
	}
	.at-icon-wrapper {
		width: 24px !important;
		height: 24px !important;
		svg {
			max-width: 100%;
			max-height: 100%;
			fill: $border-gray !important;
		}
	}
}

/* SPECIFIC PAGES */

/* Search Results */
.search_results {
	> div {
		@extend %clearfix;
	}
	.product_result {
		@include make-columns(6,3,3,2,10,10);
		height: 230px;
	}
	.product_data {
		text-align: center;
		font-size: 14px;
		padding: 15px 0 30px;
		a {
			color: $text-color;
		}
	}
}

.team {
	.team-members {
		margin-top: 60px;
	}
	.team-member {
		margin-bottom: 60px;
		img {
			margin: 0 auto;
			margin-bottom: 40px;
			@media(min-width: $screen-md) {
				margin-bottom: 0;
			}
		}
	}
}