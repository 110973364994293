//**************************//
//   Default BlogV3 Styles	//
//**************************//

// General layout styles, affecting blog_main.emt, blog_nav.emt, and blog_post.emt
.blog-main-content,
.blog-post-content {
	@include make-columns(12,12,9);
	@include make-md-column-push(3);
}

.blog-sidebar {
	@include make-columns(12,12,3);
	@include make-md-column-pull(9);
	h4 {
		font-family: $headings-font-family;
		font-size: $font-size-h3;
		color: $brand-tertiary;
		margin-bottom: 10px;
	}
	.sidebar-item {
		margin-bottom: 20px;
	}
}


/* Blog Main and Blog Nav */
.blog-main-content {
	.date {
		width: 60px;
		background-color: $brand-accent;
		position: absolute;
		top: 0;
		right: 0;
		h2 {
			width: 40px;
			height: 60px;
			color: $light;
			font-family: $font-family-base;
			font-size: 16px;
			line-height: 24px;
			font-style: normal;
			font-weight: 600;
			margin: 0 auto;
			padding-top: 4px;
			text-align: center;
			text-transform: uppercase;
			span {
				display: block;
				font-size: 27px;
				font-weight: 200;
			}
		}
	}
	.photo {
		position: absolute;
		z-index: 10;
		bottom: 10px;
		left: 8px;
		height: 90px;
		@media only screen and (min-width: $screen-sm) {
			bottom: -40px;
		}
		a {
			display: inline-block;
			height: 90px;
		}
	}
}

.cont_wrapper {
	clear: both;
	width: 90%;
}
.post_image {
	position: relative;
	width: 100%;
	/*height: 275px;*/
	img {
		width: 100%;
	}
}
.post_overview {
	margin: 20px 0;
	@media only screen and (min-width: $screen-sm) {
		margin: 20px 0 50px;
		width: 80%;
		float: right;
	}
}
.cont_head h2 {
	font-size: 26px;
	font-style: normal;
	line-height: 30px;
	font-weight: 200;
	text-transform: none;
}
.cont_main {
	font-weight: 200;
	border-bottom: 1px solid #CCCCCC;
}
.cont_main p {
	font-size: 14px;
}

.photo img {
	border-radius: 90px;
	height:90px;
	width:90px;
}

.post-content {
	clear:both;
	padding: 20px 0 0;
	@media only screen and (min-width: $screen-sm) {
		padding: 10px 0 0 15px;
	}

	h1 {
		clear: both;
		padding:15px 0;
	}
}

.blog-pagination {
	width: 90%;
	height: 70px;

	div {
		display: inline-block;
	}

	.blog_pages_prevnext {
		width: 50%;
		font-size: 14px;
		line-height: 36px;
	}

	.blog_pages_select {
		width: 150px;
		float: right;
		font-size: 14px;
		line-height: 36px;
	}

	.blog_pages_select select {
		width: 55px;
	}
}

/* Blog Post Detail */
.blog-post-content {
	.blog-icon-wrapper {
		@media only screen and (min-width: $screen-sm) {
			float: left;
			width: 140px;
			padding: 0 15px;
		}
	}

	.blog-icon {
		width:90px;
		padding-bottom: 10px;
		@media only screen and (min-width: $screen-sm) {
			
		}
	}

	.content-header {
		padding: 0 0 10px;
		border-bottom: 1px solid $border-gray;
		@media only screen and (min-width: $screen-sm) {
			float: right;
			width: calc(100% - 140px);
		}

		h4 {
			font-family: $headings-font-family;
			font-size: 38px;
			color: $brand-tertiary;
			margin-bottom: 25px;
		}
		p {
			font-size: 14px;
			@media only screen and (min-width: $screen-sm) {
				float: left;
				width: calc(100% - 185px);
				margin: 2px 0 0;
			}
		}
		.prod-share {
			height: 29px;
			width: 185px;
			padding-top: 0;
			@media only screen and (max-width: $screen-sm) {
				float: none;
			}
		}
	}


}


// COMMENTS & LOGIN SECTION
// Only visible when blog comments are enabled

#commentform {
	input[type='text'],
	textarea {
		background-color: #fff;
		color: #a1a1a1;
		font-size: 14px;
		border: #a1a1a1 solid 1px;
		height: 30px;
		width: 100%;
		padding-left: 10px;
		font-weight: 400;
		margin: 0 0 16px;
	}

	input#email {
		margin-bottom: 0;
	}

	small {
		font-size: 12px;
	}

	textarea {
		height: 76px;
		margin-bottom: 0;

		@media only screen and (max-width: $screen-sm) {
			margin-top: 15px;
		}
	}

	.commenttext, 
	.blog_comment_form_submit, 
	.register_block {
		@include make-columns(12,4);

		@media only screen and (max-width: $screen-sm) {
			padding-left: 0;
			padding-right: 0;
		}

		&.right {
			min-height: 100px;

			@media only screen and (max-width: $screen-sm) {
				float: none;
			}
		}

		&:first-child {
			@media only screen and (min-width: $screen-sm) {
				padding-left: 0;
			}
		}
	}


	.blog_comment_form_submit input {
		margin: 10px 0 0;
		width: 100%;

		@media only screen and (min-width: $screen-sm) {
			font-size: 14px;
			padding: 5px 10px;			
		}
	}

	.small.button {
		margin: 0 14px;
		padding: 10px 35px;
		text-transform: none;
		font-size: 15px;
	}	
}

.blog_comment_form_login,
.blog_comment_form_register {
	@extend .btn-default;
	width: 100%;
}
