//
// Scaffolding
// --------------------------------------------------


// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
	@include box-sizing(border-box);
}
*::before,
*::after {
	@include box-sizing(border-box);
}


// Body reset

html {
	width: auto;
	font-size: 62.5%;
	overflow-x: hidden;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	background-color: $body-bg;
	background-image: $body-bg-img;
	background-size: $body-bg-size;
	width: auto;
	line-height: $line-height-base;
	color: $text-color;
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-weight-base;
	// overflow-x: hidden;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
	line-height: inherit;
	font-family: inherit;
	font-size: inherit;
}


// Links

a {
	text-decoration: none;
	color: $link-color;

	&:hover,
	&:focus {
		text-decoration: underline;
		color: $link-hover-color;
	}

	&:focus {
		@include tab-focus();
	}
}


// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
	margin: 0;
}


// Images
img {
	@include img-responsive();
	vertical-align: middle;
}

// Horizontal rules
hr {
	margin-top: $line-height-computed;
	margin-bottom: $line-height-computed;
	border: 0;
	border-top: 1px solid $hr-border;
}

/*
 *	Common Placeholders
 */

// Center all content inside
%centered {
	text-align: center;
	> div,
	img {
		margin-right: auto;
		margin-left: auto;
	}
}

// Rounded corners
%img-rounded {
	border-radius: $border-radius-large;
}

// Image thumbnails
%img-thumbnail {
	@include transition(all .2s ease-in-out);

	// Keep them at most 100% wide
	@include img-responsive(inline-block);

	border: 1px solid $thumbnail-border;
	border-radius: $thumbnail-border-radius;
	background-color: $thumbnail-bg;
	padding: $thumbnail-padding;
	line-height: $line-height-base;
}

// Perfect circle
%img-circle {
	border-radius: 50%; // set radius in percents
}

/*
 *	Only display content to screen readers
 *
 * 	See: http://a11yproject.com/posts/how-to-hide-content/
 */

.sr-only {
	position: absolute;
	margin: -1px;
	border: 0;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
}

/*
 *	FOUC hide
 *
 * 	Class removed on document ready state in /js/init.js
 */

.no-fouc { display: none; }
