@if ($is-fluid == true) {
	.container {
		width: auto;
		max-width: $container-large-desktop;
    }
}

.wrapper,
%wrapper {
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
	padding-left: 15px;
	width: auto;
	max-width: $container-large-desktop;
	&::before,
	&::after {
		display: table;
		content: ' ';
	}
	&::after {
		clear: both;
	}
}

@mixin make-columns($xs: null, $sm: null, $md: null, $lg: null, $pad: null, $side: null) {

	//If padding not set, define bootstrap default
	@if $pad == null {
		$pad: ($grid-gutter-width / 2);
	}

	// Styles for all columns
	position: relative;
	min-height: 1px;
	float: left;

	//Left and Right Padding
	@if $side != null {
		@media screen and (max-width: $screen-xs-max) {
			@include set-col-sidepad($xs, $pad, $side);
		}
		@media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			@include set-col-sidepad($sm, $pad, $side);
		}
		@media screen and (min-width: $screen-md) and (max-width: $screen-md-max) {
			@include set-col-sidepad($md, $pad, $side);
		}
		@media screen and (min-width: $screen-lg) {
			@include set-col-sidepad($lg, $pad, $side);
		}
	} @else {
		padding-right: #{$pad};
		padding-left: #{$pad};
	}

	// Column widths
	@if $xs == null {
		width: 100%;
		@media (min-width: $screen-sm-min) {
			width: 100%;
		}
		@media (min-width: $screen-md-min) {
			width: 100%;
		}
		@media (min-width: $screen-lg-min) {
			width: 100%;
		}
	} @else if $sm == null {
		width: percentage(($xs / $grid-columns));
		@media (min-width: $screen-sm-min) {
			width: percentage(($xs / $grid-columns));
		}
		@media (min-width: $screen-md-min) {
			width: percentage(($xs / $grid-columns));
		}
		@media (min-width: $screen-lg-min) {
			width: percentage(($xs / $grid-columns));
		}
	} @else if $md == null {
		width: percentage(($xs / $grid-columns));
		@media (min-width: $screen-sm-min) {
			width: percentage(($sm / $grid-columns));
		}
		@media (min-width: $screen-md-min) {
			width: percentage(($sm / $grid-columns));
		}
		@media (min-width: $screen-lg-min) {
			width: percentage(($sm / $grid-columns));
		}
	} @else if $lg == null {
		width: percentage(($xs / $grid-columns));
		@media (min-width: $screen-sm-min) {
			width: percentage(($sm / $grid-columns));
		}
		@media (min-width: $screen-md-min) {
			width: percentage(($md / $grid-columns));
		}
		@media (min-width: $screen-lg-min) {
			width: percentage(($md / $grid-columns));
		}
	} @else {
		width: percentage(($xs / $grid-columns));
		@media (min-width: $screen-sm-min) {
			width: percentage(($sm / $grid-columns));
		}
		@media (min-width: $screen-md-min) {
			width: percentage(($md / $grid-columns));
		}
		@media (min-width: $screen-lg-min) {
			width: percentage(($lg / $grid-columns));
		}
	}
}

@mixin set-col-sidepad($srn, $pad, $side) {
	$row-count: ($grid-columns / $srn);
	// @debug $row-count;
	@if $row-count == 1 {
		padding-right: #{$side}px;
		padding-left: #{$side}px;
	} @else if $row-count == 2 {
		&:nth-child(even) {
			padding-right: #{$side}px;
			padding-left: #{$pad}px;
		}
		&:nth-child(odd) {
			padding-right: #{$pad}px;
			padding-left: #{$side}px;
		}
	} @else {
		padding-right: #{$pad}px;
		padding-left: #{$pad}px;
		&:nth-child(#{$row-count}n+0) {
			padding-right: #{$side}px;
			padding-left: #{$pad}px;
		}
		&:first-child,
		&:nth-child(#{$row-count}n+1) {
			padding-right: #{$pad}px;
			padding-left: #{$side}px;
		}
	}
}
