/*
 *  Typography
 */

a, a:visited, a:hover, a:active, a:focus {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}



// Headings
// -------------------------

%h-all {
	margin-bottom: ($line-height-computed / 2);
	line-height: $headings-line-height;
	color: $headings-color;
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	&:first-child {
		margin-top: 0;
	}
}

h1, %h1,
h2, %h2,
h3, %h3 {
	@extend %h-all;
	margin-top: $line-height-computed;
}

h4, %h4,
h5, %h5,
h6, %h6 {
	@extend %h-all;
	margin-top: ($line-height-computed / 2);
}

h1, %h1 { font-size: em($font-size-h1); }
h2, %h2 { font-size: em($font-size-h2); }
h3, %h3 { font-size: em($font-size-h3); }
h4, %h4 { font-size: em($font-size-h4); }
h5, %h5 { font-size: em($font-size-h5); }
h6, %h6 { font-size: em($font-size-h6); }


// Body text
// -------------------------

p {
	margin: ($line-height-computed / 2) 0;
	min-height: $line-height-computed; /*--setting min-height for wysiwyg-entered content --*/
}

%lead {
	margin-bottom: $line-height-computed;
	line-height: 1.4;
	font-size: floor(($font-size-base * 1.15));
	font-weight: 200;

	@media (min-width: $screen-sm-min) {
		font-size: ($font-size-base * 1.5);
	}
}

/*
 *	Emphasis & misc
 */

// Ex: 14px base font * 85% = about 12px
small,
%small { font-size: 85%; }

// Undo browser default styling
cite { font-style: normal; }

// Contextual colors
%text-muted {
	color: $text-muted;
}

/*
 *	Page header
 */

%page-header {
	margin: ($line-height-computed * 2) 0 $line-height-computed;
	border-bottom: 1px solid $page-header-border-color;
	padding-bottom: (($line-height-computed / 2) - 1);
}

/*
 *	LISTS
 */

/*
 *	Unordered and Ordered lists
 */

ul,
ol {
	margin-top: 0;
	margin-bottom: ($line-height-computed / 2);
	li {
		margin-bottom: ($line-height-computed / 2);
	}
	ul,
	ol {
		margin-bottom: 0;
	}
}

/* Call base list Mixins controlled by settings */
@include listitems ($list-style, $list-padding-left, $header-bullets, $footer-bullets, $offcanvas-bullets);
@include orderedlistcolor ($colored-ordered-list, $ol-number-color);

/*
 *	List options
 */

/* Unstyled keeps list items block level, just removes default browser padding and list-style */
%list-unstyled {
	padding-left: 0;
	list-style: none;
}

/* Inline turns list items into inline-block */
%list-inline {
	@extend %list-unstyled;
	margin-left: -5px;

	> li {
		display: inline-block;
		padding-right: 5px;
		padding-left: 5px;
	}
}

/*
 *	Description Lists
 */

dl {
	margin-top: 0; // Remove browser default
}
dt,
dd {
	line-height: $line-height-base;
}
dt {
	font-weight: bold;
}
dd {
	margin-left: 0; // Undo browser default
}

/*
 *	Horizontal description lists
 *
 * 	Defaults to being stacked without any of the below styles applied, until the
 * 	grid breakpoint is reached (default of ~768px).
 */

@media (min-width: $grid-float-breakpoint) {
	%dl-horizontal {
		dt {
			@include text-overflow();
			float: left;
			clear: left;
			width: ($component-offset-horizontal - 20);
			text-align: right;
		}
		dd {
			@include clearfix(); // Clear the floated `dt` if an empty `dd` is present
			margin-left: $component-offset-horizontal;
		}
	}
}

/*
 *	MISC
 */

/* Abbreviations and acronyms */
abbr[title],
/* Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257 */
abbr[data-original-title] {
	border-bottom: 1px dotted $abbr-border-color;
	cursor: help;
}
%initialism {
	text-transform: uppercase;
	font-size: 90%;
}

/*
 *	Blockquotes
 */

blockquote {
	margin: 0 0 $line-height-computed;
	padding: ($line-height-computed / 2) $line-height-computed;
	font-size: $blockquote-font-size;

	p,
	ul,
	ol {
		&:last-child {
			margin-bottom: 0;
		}
	}

	footer {
		display: block;
		line-height: $line-height-base;
		color: $blockquote-small-color;
		font-size: 80%; // back to default font-size

		&::before {
			content: '\2014 \00A0'; // em dash, nbsp
		}
	}
}

/* Opposite alignment of blockquote */
%blockquote-reverse {
	border-right: 5px solid $blockquote-border-color;
	border-left: 0;
	padding-right: 15px;
	padding-left: 0;
	text-align: right;

	/* Account for citation */
	footer,
	small,
	.small {
		&::before { content: ''; }
		&::after {
			content: '\00A0 \2014'; // nbsp, em dash
		}
	}
}

/*
 *	Addresses
 */
address {
	margin-bottom: $line-height-computed;
	line-height: $line-height-base;
	font-style: normal;
}
