//******************************//
//        Header Styles         //
//******************************//

/* HEADER STRUCTURE AND LAYOUT */
header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 50px;
	padding: 10px 15px;
	z-index: 1001;
	min-width: 320px;
	background-color: $light;
	@media (min-width: $screen-xs) {
		height: 70px;
	}
	@media (min-width: $screen-md) {
		padding: 18px 20px 0px;
	}

	.nav-logo {
		float: left;
		padding-right: 30px;
		@media (min-width: $screen-md){	
			padding-right: 38px;
		}
	}

	.header-left {
		float: left;
		width: calc(100% - 175px);
		@media (min-width: $screen-sm){			
			width: calc(100% - 563px);
		}
		.efelle-dropdown {
			@media (max-width: 1190px) {
				display: none; 
			}
		}
	}
	.header-right {
		float: right;
		width: 175px;
		@media (min-width: $screen-sm){
			width: 513px;
		}
		@media (min-width: $screen-md) and (max-width: 1190px){
			margin-right: 50px;
		}
		> div {
			font-size: 14px;
			@media (min-width: $screen-md){
				float: left;
			}
			i {
				font-size: 1.6em;
				line-height: 1em;
			}
		}
	}
	.header-phone {
		display: none;
		@media (min-width: $screen-md){
			display: block;
			width: 151px;
		}
		p {
			margin: 4px 0;
			line-height: 30px;
			height: 30px;
			letter-spacing: 0.07em;
			@media (max-width: 1260px){
				font-size: 12px;
			}
		}
		a {
			color: $brand-accent;
			font-weight: 700;
			line-height: 30px;
			height: 30px;
		}
	}
	.header-account {
		display: none;
		@media (min-width: $screen-md){
			display: block;
			width: 44px;
		}
		a {
			display: block;
			margin: 6px 0;
			color: $gray;
		}
	}
	.header-bag {
		width: 88px;
		float: right;
		@media (min-width: $screen-md){
			float: left;
		}
		@media (min-width: $screen-sm){
			width: 88px;
		}
		a {
			display: block;
			margin: 6px 0;
			color: $gray;
		}
	}
	.header-search {
		width: 45px;
		height: 35px;
		float: right;
		margin-right: 20px;
		@media (min-width: $screen-sm){
			position: relative;
			width: 230px;
			margin-right: 40px;
			float: right;
		}
		@media (min-width: $screen-md){
			margin-right: 0;
		}
		.search-input {
			width: 230px;
			height: 35px;
			background-color: $light-gray;
			border: 0 none;
			padding: 10px;
			line-height: 14px;
			letter-spacing: 0.03em;
			@media (max-width: $screen-sm){
				@include abs-pos(50px,0,0,0);
				width: 100%;
			}
			&.hidden {
				@media (max-width: $screen-sm){
					display: none;
				}
			}
		}
		.search-icon {
			margin: 6px 0 0 0;
			display: block;
			@media (min-width: $screen-sm){
				@include abs-pos(6px,15px);
				margin: 0;
			}
		}
	}

	/* Mobile Nav Links */
	.mobile-hamburger {
		@include abs-pos(0, 0, 0);
		height: 100%;
		.nav-toggle {
			@include abs-pos (0,0,0,auto);
			color: $brand-primary;
			background-color: $brand-tertiary;
			float: none;
			width: 50px;
			height: 50px;
			margin: 0;
			padding: 15px;
			@media (min-width: 1190px) {
				display: none;
			}
			.one,
			.two,
			.three {
				transition-duration: .2s;
				margin: 3px auto;
				background: $light;
				width: 100%;
				height: 2px;
			}
			&.on {
				opacity: 0;
				.one { transform: rotate(45deg) translate(3px, 4px); }
				.two { opacity: 0; }
				.three { transform: rotate(-45deg) translate(6px, -7px); }
			}
		}
	}
}

#nav-toggle {
	left: auto;
	right: 0;
	height: 17px;
	width: 24px;
	margin: 20px 31px;

	@media (min-width: 1140px) {
		display: none;
	}

	&:before {
		background-color: $brand-primary;
		height: 3px;
		top: 0;
		width: 24px;
	}
	span {
		background-color: $brand-primary;
		height: 3px;
		margin-top: -2px;
		width: 24px;
	}
	&:after {
		background-color: $brand-primary;
		height: 3px;
		bottom: 0;
		width: 24px;
	}
}

/* MAIN NAV WITH DROPDOWN MENU STYLES */
.efelle-dropdown {
	.efelle-menu {
		float: left;
		>li {
			display: inline-block;
			> a {
				line-height: 40px;
				height: 40px;
				padding: 0;
				letter-spacing: 0.05em;
				
			}
			> ul {
				display: none;
				> li {
					> ul {
						display: none;
					}
					&:hover {
						> ul {
							display: inline-block;
						}
					}
				}
			}
			&:hover {
				> ul {
					display: inline-block;
				}
			}
		}
	}

	// child menu item level 1
	> ul {
		li {
			height: 40px;
			a {
				margin: 0 5px;
				line-height: 40px;
				padding: 0;
				font-size: 12px;
				display: block;
				text-transform: uppercase;
				@media (min-width: $screen-md) {
					margin: 0 9px;
					font-size: 12px;
					padding: 0 5px;
				}
				@media (min-width: 1370px) {
					margin: 0 15px;
					font-size: 14px;
				}
			}
		}
		>li {
			&.active-state,
			&:hover {
				> a {
					color: $brand-secondary;
				}
			}
		}
		
		// child menu item level 2
		ul {
			top: 40px;
			background-color: $light;
			padding: 10px 0 0;
			li {
				height: 33px !important;
				line-height: 33px;
				&:hover {
					background-color: inherit;
					> a {
						border-bottom: 0 none;
						color: $brand-secondary;
						padding: 0 20px;
					}
				}
				&.is-parent {
					> a {
						position: relative;
						&:after {
							content: '+';
							position: absolute;
							right: 10px;
						}
					}
				}
				a {
					padding: 0 20px;
					letter-spacing: .05em;
					transition: none;
				}
				ul {
					left: 100%;
					top: 0;
					
				}
			}
		}
	}
}

html {
	& > .on {
		position: fixed;
		overflow: hidden;
		top: 0;
    	left: 0;
    	right: 0;
    	bottom: 0;
	}
}
.menu-overlay {
	opacity: 0;
	transition: opacity .3s linear;
	height: 0;

	ul.mobile-nav {
		display: none;
	}

	.nav-toggle {
		position: absolute;
		top: 18px;
		right: 15px;
		float: none;
		.one,
		.two,
		.three {
			transition-duration: .3s;
			margin: 5px auto;
			background: $light;
			width: 25px;
			height: 2px;
		}
	}

	&.hidden {
		display: none;
		.main-menu-toggle {
			display: none;
		}
	}


	&.on {
		position: fixed;
		overflow-y: scroll;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 9999;
		background: rgba(0,0,0,.7);
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: opacity .3s linear;
		padding: 30px 0;
		.one { transform: rotate(45deg) translate(3px, 4px); }
		.two { opacity: 0; }
		.three { transform: rotate(-45deg) translate(6px, -7px); }
		ul {
			display: block;
			font-size: 15px;
			padding: 0;
			list-style: none;
			text-align: center;
			li {
				padding-left: 0;
				display: block;
				a {
					display: block;
					font-size: 11px;
					color: $light;
					@media (min-width: $screen-md) {
						font-size: 15px;
					}
					&:hover {
						color: $brand-secondary;
					}
				}
				ul {
					li {
						a {
							font-size: 10px;

							@media (min-width: $screen-md) {
								font-size: 13px;
							}
						}
					}
				}
			}
		}

		@media (max-width: 1170px) {
			ul.mobile-nav {
				display: block;
				margin: 30px auto;
				a {
					margin-top: 10px;
					margin-bottom: 5px;
					font-size: 15px;
				}
				li ul {
					margin: 0;
				}
				li a {
					line-height: 1;
					padding: 10px 0;
				}
				li li a {
					font-size: 13px;
					padding: 0;
				}
			}
		}
	}

}


