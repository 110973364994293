//******************************//
//        Footer Styles         //
//******************************//

/* FOOTER STRUCTURE AND LAYOUT */
footer {
	background-image: url('/uploads/background/footer-bg-mobile.png');
	background-repeat: no-repeat;
	background-position: right bottom;
	background-color: $light-gray;
	height: auto;
	@media (min-width: 321px){
		background-image: url('/uploads/background/footer-bg.jpg');
		background-position: -215px bottom;
	}
	@media (min-width: $screen-md){
		height: 271px;
		background-position: -215px top;
	}
	@media (min-width: 1460px) {
		background-position: left top;
	}

	.footer-top {
		@extend %clearfix;
		height: auto;
		padding: 50px 0 171px;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		@media (min-width: $screen-md){
			height: 241px;
			padding: 60px 0 0;
			justify-content: flex-start;
		}
		> div {
			width: 100%;
			@media (min-width: 500px){
				float: left;
			}
		}
		h4 {
			color: $brand-accent;
			font-size: 36px;
			line-height: 36px;
			margin-bottom: 8px;
		}
		p {
			color: $dark-gray;
			font-size: 15px;
			letter-spacing: 0.06em;
			margin: 0 0 21px;
			a {
				color: $dark-gray;
				font-weight: 600;
			}
		}
		.footer-seal {
			width: 160px;
			padding: 0 0 20px;
			@media (min-width: $screen-sm) {
				padding: 0;
			}
		}
		.footer-content {
			flex: 1 0 100%;
			display: flex;
    		justify-content: center;
    		flex-wrap: wrap;
    		* { flex: 1 0 100%; text-align: center; }
    		img { margin: 0 auto 20px; }
			@media (min-width: 500px) {
				flex: 1 0 50%;
				* { flex: 1 0 100%; text-align: left; }
				img { margin: 0; }
			}
			@media (min-width:$screen-md) {
				flex: 1 0 25%;
			}
			h4 { width: 100%; }
		}
		// .footer-contact {
		// 	@media (min-width: 500px){
		// 		width: 50%;
		// 	}
		// 	@media (min-width: $screen-sm) {
		// 		width: 35%;
		// 		padding-left: 66px;
		// 	}
		// 	@media (min-width: $screen-md){
		// 		padding-left: 166px;
		// 	}
		// 	@media (min-width: $screen-lg){
		// 		width: 46%;
		// 		padding-left: 266px;
		// 	}
		// }
		.footer-menu {
			@extend %clearfix;
			padding: 20px 0;
			@media (min-width: 500px){
				padding: 0;
				// width: 50%;
			}
			// @media (min-width: $screen-sm){
			// 	width: 35%;
			// }
			// @media (min-width: $screen-lg){
			// 	width: 31%;
			// }
			ul {
				margin: 0;
				padding: 0;
			}
			li {
				float: left;
				width: 50%;
				margin: 0 0 5px;
				@media (min-width: $screen-md){
					width: 41%;
				}
			}
			a {
				color: $dark-gray;
			}
		}
		.footer-newsletter {
			padding: 20px 0;
			@media (min-width: $screen-sm){
				padding: 0;
				width: 30%;
			}
			@media (min-width: $screen-lg){
				width: 23%;
			}
			.newsletterv3_simple {
				@extend %clearfix;
				.newsletterv3_form {
					position: relative;
					height: 40px;
					margin: 12px 0 20px;
					width: 100%;
				}
				.newsletter-input {
					width: 100%;
					height: 40px;
					padding: 0 13px;
					font-family: $headings-font-family;
					border: 0 none;
				}
				button {
					@include abs-pos(0,0);
					height: 40px;
					background: 0 none;
					border: 0 none;
					color: $brand-accent;
				}
			}
			.social-media {
				a {
					color: $text-color;
				}
			}
		}
	}
	.footer-bottom {
		@media (min-width: $screen-sm){
			height: 30px;
		}
		p {
			color: $light;
			font-size: 12px;
			text-align: center;
			text-shadow: 1px 1px 30px #000;
			margin: 0;
			@media (min-width: $screen-sm){
				color: #8093a1;
				text-shadow: none;
			}
			a {
				color: $light;
				&:hover {
					color: $brand-accent;
				}
				@media (min-width: $screen-sm){
					color: #8093a1;
				}
			}
		}
	}
}

// fontawesome
.Facebook, .Twitter, .Pinterest, .Instagram {
	@extend .fa;
	@extend .fa-lg;
	margin-right: 10px;
}

.Facebook {
	@extend .fa-facebook;
}

.Twitter {
	@extend .fa-twitter;
}

.Pinterest {
	@extend .fa-pinterest-p;
}

.Instagram {
	@extend .fa-instagram;
}