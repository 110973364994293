@mixin accordplus($top: 11px, $background: #333) {
	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		width: 13px;
		height: 1px;
		background: $background;
		right: 0;
		top: $top;
		transition: transform .3s ease;
	}
	&:after {
		transform: rotate(90deg);
	}
}

.dealer-header {
	position: relative;
	height: auto;
	padding: 10px 0;
	@include clearfix;
	border-bottom: 1px solid #cecdcb;
	@media (min-width: $screen-lg) {
		padding: 10px 30px;
	}
	.dealer-logo {
		float: left;
		max-width: 50%;
		img {
			max-height: 84px;
		}

	}
	.dealer-menu {
		float: right;
		text-align: right;
		padding-top: 18px;
		@media (min-width: $screen-sm) {
			padding-top: 30px;
		}
		span {
			display: none;
			@media (min-width: $screen-sm) {
				display: inline;
			}
		}

		ul {
			padding: 0;
			margin: 0;
			li {
				margin: 0 0 4px;
				color: #7e94a2;
				@media (min-width: $screen-sm) {
					display: inline-block;
					&:after {
						content: '|';
						margin: 0 8px;
					}
					&:last-child{
						&:after{
							content: '';
							margin: 0;
						}
					}
				}
			}
		}
	}
}
.subpage_dealer {
	.dealer-masthead {
		position: relative;
		margin: 0;
		//display: flex;
		width: 100%;
		//align-items: center;
		min-height: 300px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right bottom;
		//top: initial;
		//transform: initial;
		&:before, &:after {
			height: 2px;
			background-color: rgba(255,255,255,.3);
			content: " ";
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
		}
		&:before {
			top: 5px;
		}
		&:after {
			bottom: 5px;
		}
		@media (min-width: $screen-sm) {

			min-height: 480px;
		}
		.wrapper {
			position: relative;
			min-height: 300px;
			@media (min-width: $screen-sm) {
				min-height: 480px;
			}
		}
		.masthead-cnt {
			@include make-columns(12,7);
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			* {
				color: #fff;
			}
		}
	}
	.main-cnt {
		margin: 0;
		display: flex;
		width: 100%;
		flex-direction: column;
		h1 {
			color: #6d6d6d;
			letter-spacing: .1em;
		}
		p {
			color: #868082;
		}
		.dealer-info, .dealer-contact {
			padding: 45px 15px 30px;
		}
		.dealer-contact {
			background: #eaebed;
			h2, h3 {
				color: #333;
			}
			h3 {
				margin: 34px 0 10px;
				font-size: 16px;
				font-family: 'Open Sans', sans-serif;
				font-weight: 600;
			}
			p {
				color: #6d6d6d;
			}
		}
		@media(min-width: $screen-sm){
			flex-direction: row;
			.dealer-info {
				width: 66.666%;
				flex-grow: 2;
				padding: 0 60px 0 15px;
				.cnt-wrap {
					padding: 90px 0 120px;
					max-width: 800px;
					// float: right;
				}
			}
			.dealer-contact {
				width: 33.333%;
				padding: 0 15px 0 30px;
				.cnt-wrap {
					max-width: 400px;
					padding: 120px 0 40px;
				}
			}
		}
		@media(min-width: $screen-md){
			.dealer-info {
				padding: 0 110px 0 15px;
			}
			.dealer-contact {
				padding: 0 15px 0 60px;
			}
		}
		@media(min-width: $screen-lg){
			.dealer-info {
				padding: 0 130px 0 15px;
				width: auto;
			    max-width: calc(1200px - 33%);
			    margin: 0 auto;
			}
			.dealer-contact {
				padding: 0 15px 0 100px;
			}
		}
	}
	.location-accord {
		margin-bottom: 8px;
		h4 {
			position: relative;
			margin: 12px 0 0;
		    padding: 8px 0 0;
		    color: #868082;
			font-size: 17px;
			font-weight: 600;
			font-family: 'Open Sans', Helvetica, sans-serif;
			line-height: 1.3;
			&.expand {
				span:after {
					transform: rotate(0deg);
				}
			}
		}
		span {
			position: relative;
			width: 13px;
			margin-left: 25px;
			@include accordplus();
			/*&:before, &:after {
				content: '';
				display: block;
				position: absolute;
				width: 13px;
				height: 1px;
				background: #868082;
				right: 0;
				top: 12px;
				transition: transform .3s ease;
			}
			&:after {
				transform: rotate(90deg);
			}*/
		}
		.loc-info {
			display: none;
			padding: 0 0 20px;
		}
		p {
			margin-top: 4px;
			margin-bottom: 0;
			//display: none;
		}
	}
	// .accord-blocks {
	// 	padding-top: 40px;
	// }
	.dealer-accord {
		position: relative;
		max-width: 470px;
		h3 {
			position: relative;
			margin: 0;
			padding-bottom: 16px;
			margin-bottom: 24px;
			border-bottom: 1px solid #cdcdcb;
			color: #333;
			@include accordplus();
			&.expand {
				&:after {
					transform: rotate(0deg);
				}
			}
		}
		.accord-cnt {
			display: none;
			padding-bottom: 30px;
			p {   //overrides for globals
				margin: 0;
				min-height: 1px;
			}
		}
	}
	#catalog {
		padding: 0 0 45px;
	}
	.catalog-tagline {
		width: 100%;
		min-height: 165px;
		display: flex;
		width: 100%;
		align-items: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right center;
		.tagline-cnt {
			@extend %wrapper;
			color: #fff;
			* {
				font-family: 'Crimson Text', serif;
				font-size: 18px;
				text-transform: uppercase;
				text-align: center;
				letter-spacing: .1em;
				font-weight: 400;
			}
		}
	}
	.sidebar {
		padding: 10px 15px;
		margin: 0 0 20px;
		@media (min-width: $screen-sm) {
			width: 33.333%;
			float: left;
			background-color: inherit;
			padding: 13px 15px 0 0;
			margin: 0;
		}
		@media (min-width: $screen-md) {
			padding: 13px 45px 0 0;
		}
		h3 {
			color: $dark-gray;
			margin: 0 0 18px;
		}
		ul {
			margin: 0;
			display: block;
			position: relative;
			li {
				width: 100% !important;
				list-style: none;
				padding: 5px 0 !important;
				margin-bottom: 0;
				border-bottom: 1px solid $border-gray;
				a {
					color: $dark-gray;
				}
				&.parent {
					position: relative;
					@include accordplus($top: 15px, $background: #333);
					&.expand {
						&:after {
							transform: rotate(0deg);
						}
					}
				}
				ul {
					position: relative;
					padding-left: 15px;
					display: none;
					li {
						display: block;
					}
				}
			}
		}
	}
	.main-column {
		@media (min-width: $screen-sm) {
			width: 66.666%;
			float: left;
		}
		@media (min-width: $screen-md) {
			.catalog-storefront-category {
			    width: 33.3333%;
			}
		}
	}
	.fancybox-prev span {
    	left: -25px;
	}
	.fancybox-next span {
    	right: -25px;
    }
	footer {
		height: auto;
		padding: 16px 0;
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		letter-spacing: .1em;
		background: #eaebed;
		ul {
			padding: 0;
			margin: 0;
			li {
				margin: 0;
				color: #7e94a2;
				@media (min-width: $screen-sm) {
					display: inline-block;
					&:after {
						content: '|';
						margin: 0 8px;
					}
					&:last-child{
						&:after{
							content: '';
							margin: 0;
						}
					}
				}
				a {
					color: inherit;
				}
				a:hover {

					color: darken(#7e94a2, 20%);
				}
			}
		}
	}
}