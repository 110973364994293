//*********************************************//
//  Default CRM Styles
//
//  Affecting pages in /account URL structure
//
//	Top portion pulled from /core/css/crm.css
//
//*********************************************//

/* Login Popup */

@media screen and (max-width: $screen-xs) {
	#loginbox {
		width: 100% !important;
		.lgnbx {
			clear: both;
			min-width: 250px;
		}
		div.lgnbx h1 {
			font-size: 20px;
			padding: 10px 0 0;
		}
		div.lgnbx_body {
			margin: 0;
			li span {
				float: none;
				display: block;
				text-align: left;
			}
			input {
				width: 100%;
			}
		}
		#submitpasswordrest {
			font-size: 12px;
		}
	}
}


/*.crm_account*/
#crm {
	/*margin: 80px 0;*/
	.clear { display: none; }
}

/* Basic Registration */
.crm_header {
	@extend %clearfix;
	.left,
	.right {
		@media screen and (min-width: $screen-sm) {
			width: 50%;
			float: left;
		}
	}
	.right {
		@media screen and (min-width: $screen-sm) {
			text-align: right;
		}
	}
}
#crm_register_form {
	@extend %clearfix;
	@media screen and (min-width: $screen-sm) {
		margin: 40px;
	}
	h3 {
		margin: 20px 0;
	}
}
.crm_register_block {
	margin: 10px 0;
	@media screen and (min-width: $screen-sm) {
		width: 50%;
		float: right;
	}
	&.steps_icons {
		@media screen and (min-width: $screen-sm) {
			width: 50%;
		}
		@media screen and (min-width: $screen-md) {
			width: 30%;
		}
		img {
			float: left;
			margin: 0 10px 5px 0;
		}
		span {
			display: block;
			height: 35px;
			line-height: 30px;
		}
	}
	&.submit_button {
		clear: right;
	}
	input {
		margin: 2px 0;
	}

}

/* Logged In - Account Page Baseline */
#crm_tabs					{ @extend %clearfix; clear: both; margin-top: 25px;}
#crm_tabs li				{ background: 0; float: left; padding: 0; margin: 0; width: auto; list-style: none;}
#crm_tabs li a				{ display: block; background-color: #999999;border-top-left-radius: 5px;border-top-right-radius: 5px;color: #FFFFFF;font-size: 14px; margin-right: 5px; padding: 5px 17px;}
#crm_tabs li a:hover,
#crm_tabs li a.active_tab	{ background-color: #777777; }

#crm_form					{ background-color: #eeeeee; border-top: 40px solid #777777;}
#crm_form .edit_left		{ float: left; width: 25%; padding: 25px 20px 15px;}
#crm_form .edit_right		{ float: right; width: 70%; padding: 25px 0 15px;}
#crm_form .edit_fullwidth	{ padding: 25px 15px 15px; }

#crm_form .crm_form							{ padding: 0 20px 0; }
#crm_form .crm_form .edit_form_label		{ width: 30%; float: left; clear: left; }
#crm_form .crm_form .edit_form_field		{ width: 68%; float: left; }
#crm_form .edit_form_field input			{  }

#crm_form .awesome			{  }

/* Table Styling */
#crm_form .edit_right table tr	{  }
#crm_form .edit_right table th	{  }
#crm_form .edit_right table td	{ padding: 5px; }
#crm_form .edit_fullwidth table td	{  }

/* Public Profile */
.crm_public h1								{ margin-bottom: 40px; }
.crm-public-left 							{ width: 30%; }
.crm-profile-image 							{ margin-bottom: 30px; max-width: 50%; }
.crm-public-right 							{ width: 65%; }
.crm-public-right h2 						{ margin-top: 0; }
.crm-public-right .crm-public-reviews 		{ border-bottom: 1px solid #eee;padding-bottom: 20px;margin-bottom: 30px; }




//*********************************************//
//  Default CRM Styles
//
//  Affecting pages in /account URL structure
//
//	Bottom portion pulled from PIP
//
//*********************************************//

.crm_account, 
.crm_account_addresses, 
.crm_account_newsletters {
	.left { float: left; }
	.right { float: right; }
	input, textarea { 
		padding: 7px; 
		margin: 3px;
		width: 90%;
		line-height: inherit;
		&.awesome {
			width: auto;
		}
	}


	/* Logged In - Account Page Baseline */
	#crm_tabs {
		margin: 0;

		ul {
			list-style: none;
			margin: 0;
		}
		
		li {
			&::before {
				content: none;
			}

		}

		li a {
			background-color: $brand-accent;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			margin-right: 5px; 
			padding: 5px 17px; 
			color: #FFFFFF;
			font-size: 14px; 
		}

		li a:hover,
		li a:focus,
		li a.active_tab {
			background-color: $brand-tertiary;
		}

		#members {
			display: none;
		}
	}

	#crm_form {
		@include clearfix;
		border-top: 40px solid $brand-accent; 
		border-bottom: 1px solid $brand-accent; 

		.crm_form {
			@media screen and (max-width: $screen-sm) {
				padding: 0;
			}
		}

		.edit_left {
			@include make-columns(12,4,3); 
			margin-top: 20px;
			color: #333333;
			font-size: 16px;
			font-weight: normal; 
		}

		.edit_right {
			@include make-columns(12,8,9);
			margin-top: 20px;

			@media screen and (max-width: $screen-sm) {
				padding-top: 0;
			}

			.edit_form_label {
				@include make-columns(12,5);

				@media screen and (max-width: $screen-sm) {
					padding: 0;
				}
			} 
			
			.edit_form_field {
				@include make-columns(12,7);

				@media screen and (max-width: $screen-sm) {
					padding: 0;
				}
			}
		}

		.edit_form_field select {
			padding: 5px;
			margin: 3px;
		}

		.crm_form h3 {
			@extend %clearfix;
			clear: both;
			margin: 0;
			padding: 40px 15px 10px;

			@media screen and (max-width: $screen-sm) {
				padding: 0;
			}
		}

		.edit_right .awesome {
			@extend .btn-default;
			/*padding: 5px 15px;
			font-size: 14px;*/
			float: right;
			margin-left: 5px;

			@media screen and (max-width: $screen-md) {
				float: none;
			}
		}
	}

	#crm_account_image {
		@include clearfix;
		margin: 0 0 20px;
	}

	.crm_form .edit_form_button {
		clear: both;
		padding-top: 15px;

		@media screen and (max-width: $screen-sm) {
			float: left;
		}
	}

	#crm_account_newsletters_form.crm_form {
		h3 {
			margin-top: 0;
		}
	}
}
