//**********************************//
//   Default FormBuilderV2 Styles	//
//**********************************//

/* Form elements style */
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	border: 1px solid $border-gray;
	font-size: 14px;
	padding: 5px 40px 5px 5px;
	background: url('/graphics/down-arrow.gif') no-repeat right 5px center;
	cursor: pointer;
}
select::-ms-expand { /* for IE 11 */
	display: none;
}

iframe {
	margin: 15px;
	width: 100%;
	@media(max-width: $screen-sm){
		height: 300px;
		margin: 15px 0 !important;
		clear: both;
	}
}

.form_field_hidden {
	display: none;
}

.formbuilderv2_form {
	span.form_field_not_required {
		display: none;
	}

	ul {
		list-style-type: none;
		padding-left: 0;
	}

	ul li {
		background-image: none;
		padding-left: 0;
	}

	.form_field_title {
		padding-bottom: 4px;
	}

	img.ui-datepicker-trigger {
		padding-top: 4px;
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 7px;
	}

	.form_field_element input[type="text"],
	.form_field_element input[type="email"],
	.form_field_element select,
	.form_field_element textarea {
		width: 90%;
		padding: 5px 10px;
	}

	.form_field_hint {
		font-size: 70%;
		width: 90%;
	}

	.form_field_element input.form_field_half {
		width: 44%;
	}

	.form_field_element input.form_field_half:last-child {
		float: right;
		margin-right: 10%;
	}

	.formbuilderv2_form_field {
		@include make-columns(12,6);
		padding: 10px 0px;

		@media only screen and (max-width: $screen-sm) {
			padding-left: 5%;
		}
	}

	.form_submit {
		@include make-columns(12);

		input[type="submit"] {
			@extend .btn-default;
			float: right;
			margin-right: 4%;
			margin-top: 30px;			
		}		
	}
}

//***************************************//
//   Error Classes FormBuilderV2 Styles  //
//***************************************//

.tip-error,
.tip-darkgray {
	color: #fff;
	padding: 15px;
	box-shadow: 0 5px 5px 0 rgba(0,0,0,.06);
}

.tip-error {
	background-image: none;
	background-color: #d12c0a;
}
.tip-darkgray {
	background-image: none;
	background-color: $gray-dark;
	width: 250px;
}



