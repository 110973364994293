//******************************//
//   	eCommerce Styles		//
//******************************//

.catalog_wrapper {
	@extend %wrapper;
	margin-top: 27px;
	h1 {
		line-height: .7em;
		margin-bottom: 26px;
	}
	.sorting_sort {
		text-align: right;
	}
	.products {
		margin: 20px 0 40px;
	}
}

.catalog-category-masthead {
	@extend %banner;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	height: 160px;
	@media (min-width: $screen-sm) {
		height: 300px;
		margin-bottom: 57px;
	}
	&:before,
	&:after {
		background-color: #9c8d70;
	}
	h4 {
		@extend %middle-align;
		color: $light;
		@media (min-width: $screen-sm) {
			font-size: 72px;
			margin-left: 100px;
		}
	}
}

.product-rating {
	div {
		display: inline-block;
	}
	img {
		display: inline-block;
		margin-right: 2px;
	}
	a {
		display: inline-block;
		font-size: 12px;
		color: $text-color;
		text-decoration: underline;
	}
}

/* Product Detail Template */
.ecom-product {
	.bct {
		ul {
			margin: 20px 0;
		}
	}
}
.product-detail {
	@extend %wrapper;
	@extend %clearfix;
	.product-media,
	.product-related,
	.product-upsells {
		@extend %clearfix;
	}
	.product-left {
		@media (min-width: $screen-sm) {
			width: 60%;
			float: left;
		}
		#product_gallery {
			margin-top: 5px;
			#gallery_items {
				padding-top: 10px;
				text-align: center;
				@media (min-width: $screen-md) {
					float: left;
					width: 17%;
				}
				.gallery_item {
					cursor: pointer;
					float: left;
					margin: 0 0 13px;
					padding: 0 2px;
					width: 25%;
					@media (min-width: $screen-xs) {
						height: 85px;
						margin: 0 0 13px 4px;
						padding: 0;
						width: 85px;
					}
					.item_metadata {
						display: none;
					}
				}
			}
			#gallery_main {
				padding-top: 29px;
				@media (min-width: $screen-md) {
					padding-top: 0;
					float: right;
					width: 83%;
				}
				img {
					width: 100%;
					@media (min-width: $screen-md) {
						width: 88%;
					}
				}
			}
		}
		h2 {
			font-size: 28px;
			margin-bottom: 25px;
		}
		.product-related {
			width: 94%;
			margin-top: 33px;
			@media (min-width: $screen-md) {
				margin-top: 93px;
			}
		}
		.catalog_product {
			width: 33%;
			padding: 0 20px 0 0;
		}
	}
	.product-right {
		@media (max-width: $screen-sm) {
			padding-bottom: 50px;
			clear: both;
		}
		@media (min-width: $screen-sm) {
			width: 38%;
			float: right;
		}
		h1 {
			color: $dark-gray;
			margin-bottom: 13px;
			text-transform: none;
			letter-spacing: 0;
		}
		.product_price {
			font-size: 22px;
			color: $gray-text;
			letter-spacing: 0.09em;
			margin-right: 31px;
			&:before {
				content: "$";
			}
			&:empty {
				margin-left: 0;
			}
		}
		.product_sku {
			font-size: 12px;
			color: $dark;
			letter-spacing: 0.04em;
		}
		.product-rating {
			margin: 10px 0 34px;
		}
		.product-description {
			padding-bottom: 26px;
			margin-bottom: 31px;
			border-bottom: 1px solid $border-gray;
		}
		.product_options {
			@extend %clearfix;
			p {
				text-transform: uppercase;
				margin: 0 0 -1px;
				color: $gray-text;
				font-size: 14px;
				letter-spacing: .04em;
				font-weight: 600;
			}
			.product_option {
				margin: 0 5px 0 0;
				width: 43px;
				height: 43px;
				padding: 2px;
				cursor: pointer;
				float: left;
				border-radius: 40px;
				border: 1px solid transparent;
				background-clip: content-box;
				&.selected,
				&:hover {
					/*box-sizing: content-box;*/
					border: 1px solid $gray;
				}
			}
		}
		.hidden-field {
			display: none;
		}
		@extend %fadein;
	}
}
.product-info-fields {
	@extend %clearfix;
	margin-top: 27px;
	/*border-bottom: 1px solid $border-gray;*/

	* {
		font-size: 14px;
		color: $gray-text;
		@media (min-width: $screen-sm) {
			line-height: 32px;
		}
	}
	> div {
		@extend %clearfix;
		width: 100%;
		margin-bottom: 8px;
	}
	label {
		display: block;
		@media (min-width: $screen-md) {
			float: left;
			width: 35%;
			padding: 3px 0;
		}
	}
	input {
		width: 100%;
		height: 32px;
		@media (min-width: $screen-md) {
			float: right;
			width: 65%;
			margin: 3px 0;
		}
	}
	.btn-default {
		font-size: 20px;
		padding: 8px 67px;
		margin-top: 25px;
		margin-bottom: 12px;
		color: $light;
	}
	.additional-options {
		strong {
			text-transform: uppercase;
			margin: 0;
			letter-spacing: .025em;
			font-weight: 600;
		}
	}
}
.product-contact {
	.btn {
		margin-bottom: 20px;
		@media (min-width: $screen-sm) {
			margin-bottom: 0;
		}
	}
}
.product-contact-form {
	display: none;
	.formbuilderv2_form {
		@include clearfix();
		background-color: $light-gray;
		padding: 30px;
		h1 {
			display: none;
		}
		.form_description {
			h4 {
				font-family: $headings-font-family;
				font-size: 1.5em;
				line-height: 1.1;
				color: $dark-gray;
				margin: 0;
			}
			hr {
				display: none;
			}
			p {
				margin: 10px 0;
			}
		}
		.form_fields {
			.formbuilderv2_form_field {
				width: 100%;
				padding: 5px 0;
				margin: 0;
				.form_field_element input[type="text"],
				.form_field_element input[type="email"],
				.form_field_element select,
				.form_field_element textarea {
					width: 100%;
					padding: 5px 10px;
				}
				.form_field_element input.form_field_half {
					width: 48%;
				}
				.form_field_element input.form_field_half:last-child {
					margin-right: 0;
				}
			}
		}
		.form_submit {
			padding: 0;
			input[type=submit]{
				margin: 0;
			}
		}
	}
}

.product-infotabs {
	border-bottom: 1px solid $border-gray;
	@media (min-width: $screen-sm) {
		margin-top: 50px;
	}
	.info-header {
		font-size: 1.25em;
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		text-transform: uppercase;
		color: $link-color;
		padding: 13px 0;
		border-top: 1px solid $border-gray;
		cursor: pointer;
		&:before {
			content: "\f067";
			font-family: FontAwesome;
			color: #6dadda;
			width: 10px;
			font-size: 14px;
			padding: 0 18px 0 0;
		}
		&.open {
		&:before {
			content: "\f068";
		}	
		}
	}
	.info-content {
		display: none;
		padding: 0 0 20px 30px;
	}
}

.product-reviews {
	@extend %wrapper;
	padding: 0 0 60px;
	h3 {
		color: $dark-gray;
	}
	> h3 {
		font-size: 1.25em;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	    @extend %centered-line;
	}
	.product-review-top {
		@extend %clearfix;
		padding: 0 15px;
		margin-bottom: 25px;
		.product-rating {
			float: left;
		}
		.btn-default {
			float: right;
			@media (max-width: $screen-sm) {
				font-size: 14px;
				padding: 7px 14px;
			}
			@media (min-width: $screen-sm) {
				margin-right: 130px;
			}
		}		
	}

	.product-review-form {
		@extend %clearfix;
		display: none;
		margin: 15px;
		padding: 15px;
		border: 1px solid $border-gray;
		@media (min-width: $screen-sm) {
			width: 50%;
			margin: 40px auto;
			padding: 20px;

		}
		.product-rating {
			margin: 10px 0;
			.review_form_rating_label {
				font-size: 14px;
				display: inline-block;
				line-height: 22px;
			}
		}
		input,
		textarea {
			margin-bottom: 5px;
			width: 100%;
		}
	}
	.review {
		@extend %clearfix;
		padding: 0 15px 45px;
	}
	.review-left {
		p {
			@media (max-width: $screen-sm) {
				font-size: 12px;
				margin: 0;
			}		
		}
		@media (min-width: $screen-sm) {
			float: left;
			width: 27%;
		}
	}
	.review-right {
		@media (min-width: $screen-sm) {
			float: left;
			width: 57%;
		}
		.product-rating {
			padding: 8px 0;
		}
		h3 {
			margin: 5px 0 13px;
		}
		p {
			margin: 0;
			letter-spacing: -0.005em;
		}
	}
}

.spotlight-banner {
	@extend %banner;
	height: 220px;
	background: url('/uploads/images/spotlight-banner.png') no-repeat;
	background-size: cover;
	margin: 60px 0;
	@media (min-width: $screen-sm) {
		height: 360px;
	}
	&:before,
	&:after {
		background-color: #9c8d70;
	}
	.spotlight-content {
		@extend %wrapper;
		height: 132px;
		text-align: center;
		@media (min-width: $screen-sm) {
			height: 272px;
		}
		.spotlight-header {
			@extend %clearfix;
			height: 40px;
			margin-bottom: 20px;
		}
		h5 {
			display: inline;
			color: $light;
			letter-spacing: .118em;
			font-size: 16px;
			font-weight: 500;
			margin: 0;
			@media (min-width: $screen-sm) {
				display: inline-block;
				font-size: 1.25em;
			}
		}
		#tab-header {
			display: inline;
			text-align: left;
			border-bottom: 1px solid #a39474;
			color: $brand-primary;
			background: url('/graphics/down-arrow-gold.png') right 5px center no-repeat;
			padding: 5px 30px 5px 5px;
			font-size: 1.25em;
			font-family: $headings-font-family;
			width: 215px;
			position: relative;
			cursor: pointer;
			@media (min-width: $screen-sm) {
				display: inline-block;
			}
			li {
				margin-bottom: 0;
			}
			#tab-wrapper {
				list-style: none;
				padding: 0;
				ul {
					
					box-shadow: 6px 6px 8px 0 rgba(0,0,0,.2);
					position: absolute;
					right: 0px;
					top: 39px;
					width: 100%;
					z-index: 0;
					margin: 0;
					padding: 0;
					background: $brand-secondary;
					opacity: 0;
					&.active {
						@extend %default-trans;
						z-index: 9;
						opacity: 1;
						max-height: 400px;
						overflow-y: auto;
						overflow-x: hidden;
					}
					li {
						padding: 0;
						a {
							padding: 0 10px 5px;
							display: block;
							text-transform: capitalize;
							font-size: 16px;
						}
					}
				}
				> a {
					text-transform: uppercase;
				}
			}
			a {
				color: $brand-primary;
			}
		}
		.tab-content {
			opacity: 0;
			position: absolute;
			top: 40px;
			right: 0;
			left: 0;
			&.show {
				opacity: 1;
				height: 70px;
				@media (min-width: $screen-sm) {
					height: 162px;
				}
			}
		}
		.epitaph-slider {
			.slide {
				height: 70px;
				@media (min-width: $screen-sm) {
					height: 162px;
				}
			}
		}
		blockquote {
			font-size: 16px;
			margin: 0;
			padding: 0;
			p {
				color: $light;
				font-family: $accent-font-family;
				padding: 20px;
				text-transform: none;
				font-size: 1.8em;
				line-height: 1em;

				@media (min-width: $screen-sm) {
					font-size: 3.26em;
					padding: 60px 0 80px;
				}
			}
		}
	}
}

/* Add to Cart Popup */

#addcartdiv {
	font-size: 14px;
	h1 {
		font-size: 18px;
		margin-bottom: 10px;
	}
	#buttons {
		input {
			@extend .btn;
			@extend .btn-default;
			padding: 5px 15px;
		}
	}
}

/* Checkout */
.main-cnt {
	#paymentbox {
		margin: 80px auto;
		input[type="button"] {
			@extend .btn;
			@extend .btn-default;
			padding: 5px 15px;
			font-size: 14px;
			min-width: 140px;
		}
	}
	#payment_complete_page {
		margin: 80px auto;
		.confmtop table {
			border-top: 0 none;
		}
		.confm h2 {
			font-style: normal;
			font-family: $headings-font-family;
		}
		@media print {
			margin: 0;
			#thisorder {
				float: none;
				margin-bottom: 40px;
			}
			table {
				p {
					font-size: 14px;
				}
			}
		}
	}
	#loginactbox {
		width: 320px !important;
		border: 0 none;
		padding: 0 0 100px;
		.logincontainer {
			ul li input {
				line-height: inherit;
			}
		}
	}
}

#lgnbx_reset div.lgnbx_body {
	font-size: 14px;
	input {
		width: auto;
	}
}


