//******************************//
//   Default eService Styles	//
//******************************//

// Service category styles
.service-category {
	margin: 60px 0 0;
	.category-description {
		@extend %wrapper;
		max-width: 830px;
		text-align: center;
		h4 {
			margin: 7px 0 0;
			color: $text-color;
		}
		h2 {
			margin: 1px 0 13px;
			letter-spacing: .08em;
			font-size: 2.625em;

			&:after {
				background-color: $border-gray;
				content: "";
				display: block;
				height: 1px;
				position: relative;
				width: 70px;
				margin: 17px auto 0;
			}
		}
	}
	.services {
		@extend %clearfix;
		width: calc(100% - 16px);
		margin: 0 auto;
		float: none;
	}
	.service {
		@extend %blur-hover;
		position: relative;
		margin: 4px;
		@media (min-width: $screen-md){
			float: left;
			width: calc(50% - 8px);
		}
		img {
			width: 100%;
		}
		.service-info {
			@include abs-pos(0,0,0,0);
			@extend %clearfix;
			display: block;
			.service-info-wrap {
				display: block;
				
				margin: 20% 15px;
				text-align: center;
				color: $light;
				@media (min-width: $screen-md){
					width: 60%;
					margin: 25% auto 0;
				}
				@media (min-width: $screen-lg){
					margin: 30% auto 0;
				}
			}
			h2 {
				color: $light;
				@media (max-width: $screen-md){
					margin: 0;
				}
			}
			hr {
				margin: 10px auto;
				@media (min-width: $screen-md){
					margin: 24px auto 18px;
				}				
			}
		}
	}
}
.service-related {
	@extend %wrapper;
	max-width: 710px;
	text-align: center;
	padding-top: 60px;
	padding-bottom: 40px;
	h2 {
		margin-bottom: 14px;
	}
}

.service-testimonial {
	@extend %wrapper;
	padding: 0;
	position: relative;
	.service-testimonial-wrap {
		@include abs-pos(0,0,10%,0);
		padding: 30% 15% 0;
		text-align: center;
		* {
			color: $light;
		}
		h4 {
			font-size: 52px;
			line-height: .95;
			margin-bottom: 14px;
		}
		p {
			margin-bottom: 18px;
			text-transform: uppercase;
		}
		a {
			font-family: $headings-font-family;
			font-style: italic;
			font-weight: 400;
			font-size: 1.375em;
			text-transform: none;
		}
	}
}

// Service detail styles
.service-product {
	.service-description {
		@extend %wrapper;
		margin: 60px auto;
		h4 {
			color: $text-color;
			@media (min-width: $screen-md){
				margin: 7px 160px 0 60px;
			}
			@media (min-width: $screen-lg){
				margin: 7px 260px 0 115px;
			}
		}
		h2 {
			margin: 1px 0 13px;
			letter-spacing: .08em;
			font-size: 2.625em;
			@media (min-width: $screen-md){
				margin: 1px 160px 13px 60px;
			}
			@media (min-width: $screen-lg){
				margin: 1px 260px 13px 115px;
			}
			&:after {
				background-color: $border-gray;
				content: "";
				display: block;
				height: 1px;
				position: relative;
				width: 70px;
				margin: 17px 0 0;
			}
		}
		h3 {
			@media (min-width: $screen-md){
				margin: 30px 160px 30px 60px;
			}
			@media (min-width: $screen-lg){
				margin: 30px 260px 30px 115px;
			}
		}
		p {
			@media (min-width: $screen-md){
				margin: 11px 160px 20px 60px;
			}
			@media (min-width: $screen-lg){
				margin: 11px 260px 20px 115px;
			}
		}
	}
}

.plain-testimonial {
	@extend %wrapper;
	max-width: 948px;
	margin: 62px auto;
	h4 {
		color: $text-color;
		font-size: 3.250em;
		line-height: .94em;
		text-align: center;
		margin-bottom: 18px;
	}
	p {
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 12px;
		a {
			text-transform: none;
			font-family: $headings-font-family;
			font-weight: 400;
			font-size: 1.375em;
			font-style: italic;
		}
	}
}

