/*** Dropdown Menu ***/

.efelle-dropdown > ul,
.efelle-dropdown > ul ul,
.efelle-dropdown > ul li,
.efelle-dropdown > ul a {
	z-index: 5000;
	margin: 0;
	outline: none;
	border: 0;
	padding: 0;
}

.efelle-dropdown > ul li {
	display: block;
	position: relative;
	float: left;
	height: 40px;
	list-style: none;
	a {
		display: block;
		transition: color .2s ease-in-out;
		margin: 6px 0;
		padding: 0 14px;
		text-decoration: none;
		line-height: 28px;
		color: $navbar-default-link-color;
		font-family: $navbar-font-family;
		font-size: $navbar-font-size;
		font-weight: $navbar-font-weight;
	}
}

.efelle-dropdown > ul li:hover > a { color: $navbar-default-link-hover-color; }

.efelle-dropdown > ul ul {
	position: absolute;
	top: 40px;
	left: 0;
 
	opacity: 0;
	background: rgba($navbar-dropdown-bg, .95);
 
	// -webkit-border-radius: 0 0 5px 5px;
	// -moz-border-radius: 0 0 5px 5px;
	// border-radius: 0 0 5px 5px;
 
	-webkit-transition: opacity .25s ease .1s;
	-moz-transition: opacity .25s ease .1s;
	-o-transition: opacity .25s ease .1s;
	-ms-transition: opacity .25s ease .1s;
	transition: opacity .25s ease .1s;
}
 
.efelle-dropdown > ul li:hover > ul { opacity: 1; }
 
.efelle-dropdown > ul ul li {
	height: 0;
	overflow: hidden;
	padding: 0;
	width:100%;
 
	-webkit-transition: height .25s ease .1s;
	-moz-transition: height .25s ease .1s;
	-o-transition: height .25s ease .1s;
	-ms-transition: height .25s ease .1s;
	transition: height .25s ease .1s;
}
 
.efelle-dropdown > ul li:hover > ul li {
	position: relative;
	height: 36px;
	overflow: visible;
	padding: 0;
}

.efelle-dropdown > ul ul li a {
	display: block;
	width: auto;
	padding: 4px 20px 4px 20px;
	margin: 0;
	white-space: nowrap;
 
	border: none;
	// border-bottom: 1px solid #353539;

	-webkit-transition: all .25s ease .25s;
	-moz-transition: all .25s ease .25s;
	-o-transition: all .25s ease .25s;
	-ms-transition: all .25s ease .25s;
	transition: all .25s ease .25s;
}
.efelle-dropdown > ul ul li:hover {
	background: darken(rgba($brand-secondary, .5), 15%);
}
.efelle-dropdown > ul ul li:hover a {
	// background: darken(rgba($brand-secondary, .5), 15%);
	padding-left: 15px;
}

/*** MENU TESTING ***/

#nav-toggle
{
	display: block;
	width: 40px;
	height: 36px;
	margin: 20px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	// @extend .visible-xs;
}
#nav-toggle:before,
#nav-toggle:after,
#nav-toggle span
{
	background: #000;
	content: '';
	display: block;
	width: 30px;
	height: 6px;
	position: absolute;
	left: 5px;
}
#nav-toggle:before
{
	top: 5px;
}
#nav-toggle:after
{
	bottom: 5px;
}
#nav-toggle span
{
	top: 50%;
	margin-top: -3px;
}

// @media all and (min-width: 1430px)
// {
//     #nav-toggle {
//         display: none;
//     }
// }

/* Hamburger animation */
#nav-toggle:before,
#nav-toggle:after,
#nav-toggle span
{
	transition: none 0.5s ease 0.5s;
	transition-property: transform, top, bottom, left, opacity;
}
html.mm-opening #nav-toggle span
{
	left: -50px;
	opacity: 0;
}
html.mm-opening #nav-toggle:before
{
	transform: rotate( 45deg );
	top: 15px;
}
html.mm-opening #nav-toggle:after
{
	transform: rotate( -45deg );
	bottom: 15px;
}