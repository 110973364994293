.tip-error {
	z-index:1000;
	text-align:left;
	border: 1px solid #992a23;
	border-radius:5px;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	padding:6px 8px;
	min-width:50px;
	max-width:300px;
	color:#992a23;
	background-color:#fbe3e4;
	/**
	 * - If you set a background-image, border/padding/background-color will be ingnored.
	 *   You can set any padding to .tip-inner instead if you need.
	 * - If you want a tiled background-image and border/padding for the tip,
	 *   set the background-image to .tip-inner instead.
	 */
}
.tip-error .tip-inner {
	font:12px/16px arial,helvetica,sans-serif;
}

/* Configure an arrow image - the script will automatically position it on the correct side of the tip */
.tip-error .tip-arrow-top {
	margin-top:-6px;
	margin-left:-5px; /* approx. half the width to center it */
	top:0;
	left:50%;
	width:9px;
	height:6px;
	background:url(tip-error_arrows.gif) no-repeat;
}
.tip-error .tip-arrow-right {
	margin-top:-4px; /* approx. half the height to center it */
	margin-left:0;
	top:50%;
	left:100%;
	width:6px;
	height:9px;
	background:url(tip-error_arrows.gif) no-repeat -9px 0;
}
.tip-error .tip-arrow-bottom {
	margin-top:0;
	margin-left:-5px; /* approx. half the width to center it */
	top:100%;
	left:50%;
	width:9px;
	height:6px;
	background:url(tip-error_arrows.gif) no-repeat -18px 0;
}
.tip-error .tip-arrow-left {
	margin-top:-4px; /* approx. half the height to center it */
	margin-left:-6px;
	top:50%;
	left:0;
	width:6px;
	height:9px;
	background:url(tip-error_arrows.gif) no-repeat -27px 0;
}