//
// Basic print styles
// --------------------------------------------------
// Source: https://github.com/h5bp/html5-boilerplate/blob/master/css/main.css

@media print {

	* {
		box-shadow: none !important;
		background: transparent !important;
		text-shadow: none !important;
		color: #000 !important; // Black prints faster: h5bp.com/s
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	// Don't show links for images, or javascript/internal links
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; // h5bp.com/t
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	// Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
	// Once fixed, we can just straight up remove this.
	select {
		background: #fff !important;
	}

	// Bootstrap components
	.navbar {
		display: none;
	}
	.table {
		td,
		th {
			background-color: #fff !important;
		}
	}
	.btn,
	.dropup > .btn {
		> .caret {
			border-top-color: #000 !important;
		}
	}
	.label {
		border: 1px solid #000;
	}

	.table {
		border-collapse: collapse !important;
	}
	.table-bordered {
		th,
		td {
			border: 1px solid #ddd !important;
		}
	}

}
