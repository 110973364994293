//**************************************************************//
//   Catalog Styles (used for ecom and ecat templates)	//
//**************************************************************//


// Catalog Storefront
#ecatalog {
	.sidebar {
		h3 {
			margin: 0 0 16px;
		}
		ul > li {
			padding: 5px 0;
			margin-bottom: 0;
			border-bottom: 1px solid $border-gray;
			@media (min-width: $screen-md) {			
				width: 85%;
			}
			li {
				border-bottom: 0 none;
				padding: 0;
			}
		}
	}
	.catalog-category-masthead {
		@media (max-width: $screen-md) {
			margin-bottom: 20px;
		}
		h4 {
			padding: 15px;
			@media (min-width: $screen-md) {
				width: 43%;
				padding: 0;
			}
		}
	}
	.catalog-description {
		@media (min-width: $screen-md) {
			width: 80%;
			padding: 0 0 0 10px;
			margin-bottom: 68px;
		}
		p {
			line-height: 1.35em;
		}
	}
	.catalog_product {
		.product_img {
			overflow: hidden;
			display: table-cell;
			vertical-align: middle;
			height: 180px;
			@media (min-width: $screen-xs) {
				height: 285px;
			}
			img {
				max-height: 100%;
			}
		}
	}
	.product-thumbnails {
		height: 300px;
		overflow-y: scroll;
		.catalog-thumb {
			width: 155px;
			padding: 10px;
			float: left;
			height: 200px;
			.product_img {
				height: 80px;
				img {
					max-height: 100%;
				}
			}
			.product_data {
				text-align: center;
			}
		}
	}
	/* ecat showproducts template image slider */
	.product-image {
		@extend %clearfix;
		height: 330px;
		margin-bottom: 50px;
		padding-bottom: 50px;
		border-bottom: 1px solid $border-gray;

		#cat-prev,
		#cat-next {
			@include make-columns(2);
			@extend %middle-align;
			cursor: pointer;
			&:before{
				@extend .fa;
				font-size: 40px;
			}
		}
		#cat-next {
			&:before{
				content: "\f105";
				@extend .fa-angle-right;
			}
		}
		#cat-prev {
			text-align: right;
			&:before{
				content: "\f104";
				@extend .fa-angle-left;
			}
		}
		#main-image-title {
			text-align: center;
			margin: 0 0 20px;
		}
		.main-image {
			@include make-columns(8);
			@extend %middle-align;
			img {
				margin: 0 auto;
				max-height: 180px;
			}
		}
		#main-image-blurb {
			p {
				text-align: center;
				padding: 20px 0 0;
				margin: 0;
			}
		}
	}
	.thumbnail {
		cursor: pointer;
		margin: 0 auto;
	}
	.product_data {
		margin: 15px 0 30px;
	}
}
.catalog-storefront-category {
	@include make-columns(12,6,3,3,10,10);
	@media (min-width: $screen-md) {
		height: 361px;
	}
	.cat-img {
		@media (min-width: $screen-md) {
			height: 250px;
		}
		img {
			margin: 0 auto;
		}
	}
	.cat-title {
		text-align: center;
		width: 80%;
		margin: 0 auto;
	}
}

// Product Category Page

#catalog_header {
	margin: 60px 0;
}

#catalog_toggle {
	@include clearfix;
	margin-bottom: 30px;
}

.catalog_sorting {
	float: left;
}

.catalog_paging {
	float: right;
}

.catalog_product {
	@include make-columns(12,6,3,3,10,10);
	height: 300px; /* make height of each product consistent */
	@media (min-width: $screen-sm) {
		height: 380px; /* make height of each product consistent */
	}
	.product_img {
		height: 235px;
		@media (min-width: $screen-xs) {
			height: 235px;
		}
		img {
			margin: 0 auto;
		}
	}
	.product_data {
		text-align: center;
		padding: 18px 0;
		a {
			color: $dark;
		}
		.title {
			margin-bottom: 20px;
			@media (max-width: $screen-sm) {
				font-size: 14px;
			}
		}
		.price {
			color: $brand-tertiary;
			font-family: $headings-font-family;
			font-size: 21px;
		}
	}
}

// eCatalog Product Detail Page

.catalog_product_default {
	padding-top: 50px;
	
	#product_gallery,
	#product_information, 
	#product_socialmedia {
		padding-bottom: 50px;
	}
}

#product_infotabs {
	padding-top: 50px;
	
	li { 
		background-image: none; 
	}
}
