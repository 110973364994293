/* WIDTH TEST - div at bottom of site to test for mobile size, used by base.js functions */
.width-test {
	display: none;
	width: 1px;
	height: 0;

	@media (min-width: $screen-sm) {
		display: block;
	}
}

//**********************//
//  Global Site Styles  //
//**********************//

html {
	position: relative;
	height: 100%;
	width: 100%;
}
body {
	min-width: 320px;
}
h1,.h1 {
	color: $brand-tertiary;
	line-height: 1;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	margin: 0 0 20px;
	@media (max-width: $screen-xs-max) { font-size: 30px; }
}
h2,.h2 {
	color: $brand-tertiary;
	line-height: 0.95;
	letter-spacing: 0;
	margin: 30px 0;
	@media (max-width: $screen-xs-max) { font-size: 28px; }
}
h3,.h3 {
	color: $brand-tertiary;
	line-height: 0.96;
	margin: 30px 0;
}
h4,.h4 {
	font-family: $accent-font-family;
	color: $brand-secondary;
	font-weight: 400;
	line-height: 0.78;
	margin: 40px 0;
}
h5,.h5 {
	color: $dark-gray;
	line-height: 0.9;
	font-weight: 600;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	margin: 30px 0 20px;
}
h6,.h6 {
	color: $dark-gray;
	font-family: $headings-font-family;
	margin: 30px 0 20px;
	text-transform: uppercase;
}
a,
a:hover,
a:focus,
a:link,
a:visited,
a:active {
	@extend %default-trans;
}
p {
	margin: 11px 0 20px;
	a {
		font-weight: 600;
	}
}
hr {
	border-top: 1px solid #cecdcb;
	width: 70px;
}

// Form fields
input,
textarea {
	font-size: 14px;
	line-height: 32px;
	padding: 0 5px;
	border: 1px solid $border-gray;
}

// Add all .btn classes to basic site buttons, so that both classes aren't required for implementation
.btn {
	text-transform: uppercase;
	letter-spacing: .1em;
	display: inline-block;
	&:hover,
	&:focus {
		color: $light;
	}
}
.btn-primary,
.btn-default {
	@extend .btn;
}
.awesome,
button,
input[type="button"],
input[type="submit"] {
	@extend .btn;
	@extend .btn-default;
	padding: 5px 15px;
	font-size: 14px;
}

.main-cnt {
	@extend %clearfix;
	margin-top: 56px;
	@media (min-width: $screen-xs) {
		margin-top: 70px;
	}
}
.sidebar {
	background-color: $light-gray;
	padding: 10px 15px;
	margin: 0 0 20px;
	
	@media (min-width: $screen-sm) {
		width: 17%;
		float: left;
		background-color: inherit;
		padding: 13px 0 0;
		margin: 0;
	}
	h3 {
		color: $dark-gray;
		margin: 0 0 18px;
		&.mobile {
			cursor: pointer;
			font-size: 18px;
			text-transform: uppercase;
			margin: 0;
			&:after {
				content: "\f107";
				@extend .fa;
				@extend .fa-angle-down;
				padding-left: 5px;
				font-size: 22px;
			}
			&.open {
				&:after {
					content: "\f106";
					@extend .fa-angle-up;
				}
			}
		}
	}
	ul {
		margin: 20px 0 0 0;
		padding: 0;
		display: none;
		@media (min-width: $screen-sm) {
			display: block;
			margin: 0;
		}
		&.open {
			display: block;
		}
		li {
			width: 80%;
			list-style: none;
			margin-bottom: 8px;
		
			a {
				color: $dark-gray;
			}
			ul {
				padding: 2px 0 2px 20px;
				margin: 0;
				li {
					/*font-size: 14px;*/
					width: 100%;
					margin: 5px 0;
				}
			}
			&.active {
				display: block;
				> ul > li {
					display: block;
				}
				> a {
					color: $brand-tertiary;
				}
			}
		}
		li li {
			display: none;
		}

	}
}
.main-column {
	@media (min-width: $screen-sm) {
		width: 83%;
		float: left;
	}
}


/* Subpage styles re-used throughout site */
.masthead {
	height: 200px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	@media (min-width: $screen-lg) {
		height: 700px;
	}

	.bct {
		* {
			color: $light;
			text-shadow: 1px 1px 30px #000;
		}
	}

	.masthead-caption > * {
		text-align: center;
		color: $light;
	}

	.masthead-title {
		@extend %wrapper;
		@extend %middle-align;
		text-align: center;
		h5 {
			color: $light;
			font-size: 20px;
			font-weight: 200;
			margin: 0;

			@media (min-width: $screen-sm) {
				font-size: 40px;
			}
			&:after {
			    content: '';
				background-color: $light;
			    display: block;
			    width: 100px;
			    height: 1px;
			    margin: 35px auto 0;
			}
		}
	}
}
%banner {
	height: 132px;
	margin-bottom: 6px;
	position: relative;
	&:before,
	&:after {
		content: " ";
		display: block;
		width: 100%;
		height: 1px;
		position: absolute;
	}
	&:before {
		top: 5px;
	}
	&:after {
		bottom: 5px;
	}
	> div {
		@extend %wrapper;
		@extend %middle-align;
		/*height: 132px;
		display: table-cell;
		vertical-align: middle;*/
		@media(max-width: $screen-md) {
			max-width: 563px;
		}
		h2,
		p {
			margin: 0;
			text-align: center;
			color: $light;
			text-transform: uppercase;
			font-family: $headings-font-family;
			font-size: 1.375em;
			line-height: 1em;
			letter-spacing: 0.05em;
			padding: 0;

			a {
				text-transform: none;
				font-style: italic;
				font-weight: 400;
				color: $brand-primary;
				letter-spacing: 0.025em;
				@media(max-width: $screen-md) {
					display: block;
				}
			}
		}
	}
}
.blue-banner {
	@extend %banner;
	background-color: $brand-accent;
	&:before,
	&:after {
		background-color: #496b85;
	}
}
.brown-banner {
	@extend %banner;
	background-color: $brand-secondary;
	&:before,
	&:after {
		background-color: #a39474;
	}

}

.spotlight-blocks,
%spotlight-blocks {
	@extend %clearfix;
	.spotlight-block {
		@extend %blur-hover;
		width: calc(100% - 30px);
		height: 350px;
		margin: 15px;
		position: relative;		
		@media(min-width: $screen-sm){
			float: left;
			width: calc(25% - 5px);
			height: auto;
			padding: 17% 0;
			margin: 5px 5px 5px 0;
		}
		> img {
			@include abs-pos(0,0,0,0);
			min-width: 100%;
			min-height: 100%;
			@media (min-width: $screen-sm){
				width: 100%;
				height: 100%;
			}
		}
		.spotlight-block-over {
			@include abs-pos(35%,0,0,0);
			@media (min-width: $screen-sm){
				@include abs-pos(15%,0,0,0);
			}
			@media (min-width: $screen-md){
				@include abs-pos(35%,0,0,0);
			}
			width: 100%;
			padding: 0 10%;
			text-align: center;
			img {
				margin: 0 auto;
			}
			* {
				color: $light;
			}
		}
	
	}
}
@media(min-width: $screen-sm){
	.spotlight-blocks a:first-child .spotlight-block {
		width: calc(50% - 10px);
		margin: 5px;
	}
}

.subpage_popup {
	padding: 15px;
	p {
		font-size: 14px;
	}
}

@media print {
	header,
	footer {
		display: none;
	}
}
