//**********************************//
//   Default Testimonials Styles	//
//**********************************//

// Overview page styles
.testimonial-desc {
	@media (min-width: $screen-md) {
		margin-bottom: 80px;
	}
	h4 {
		margin-bottom: 0;
	}
	h1 {
		@media (min-width: $screen-md) {
			font-size: 48px;
		}
	}
}

.testimonial-block {
	@extend %clearfix;
	padding-bottom: 40px;
	.testimonial-text {
		@media (min-width: $screen-md) {
			width: 58%;
			float: right;
		}
	}

	.testimonial-image {
		@media (min-width: $screen-md) {
			width: 36%;
			float: left;
		}
		img {
			padding-right: 0 !important;
		}
	}
	&:nth-child(even){
		.testimonial-text {
			float: left;
		}

		.testimonial-image {
			float: right;
		}
	}
	.testimonial-text {
		h2 {
			margin: 18px 0 21px;
		}
		p {
			a {
				font-weight: 600;
			}
		}
	}
}

// Detail page styles
/* ------ Testimonials ------ */

.testimonial-wrap {
	padding-bottom: 32px; 
	@extend %clearfix;
	h4 {
		margin-bottom: 15px;
	}
	h1 {
		margin-bottom: 40px;
	}
	.testimonial-info {
		min-height: 120px;
		position: relative;
		.blurb {
			background-image: url('/graphics/quote-lg.png');
			background-repeat: no-repeat;
			margin-bottom: 0;
			padding: 30px 0 0;
			@media (min-width: $screen-md) {
				padding: 32px 40px 0; 
			}
			a { font-style: normal; }
		}
		.sig { 
			display: inline-block;
			font-size: 15px; 
			line-height: 1.6;
			text-transform: uppercase;
			vertical-align: middle;
			margin: 0;
			@media (min-width: $screen-md) {
				padding: 23px 40px 0;
			}
			span { font-weight: bold; }
		}
		.full {
			padding: 30px 40px 58px 0;
			p {
				margin: 7px 0 12px;
			} 
		}
	}
	.testimonial-col {
		@include make-columns(12,12,6,6,0,0);
	}
	.img-col {
		@include make-columns(12,12,6,6,0,0);
	}
}