//******************************//
//       Homepage Styles        //
//******************************//

.homepage-masthead {
	position: relative;
	margin: 0 auto 6px;
	width: 97%;
	height: 420px;
	overflow: hidden;
	@media(min-width: $screen-md){
		height: auto;
	}
	video {
		max-width: none;
		@media(min-width: $screen-md){
			width: 100%;
		}
	}
	.video-overlay {
		@include abs-pos(0,0,0,0);
		background:rgba(0,0,0,0.2);
		z-index: 9;
		.masthead-text {
			@include abs-pos(23%,0,0,0);
			@media(min-width: $screen-md){
				@include abs-pos(35%,0,0,0);
				margin: 0 auto;
			}
		}
		width: 100%;
		padding: 0 15px;
		text-align: center;
		* {
			color: $light;
		}
		h1,
		h3 {
			font-size: 31px;
			line-height: 1em;
			max-width: 800px;
			margin: 0 auto 21px;
			text-transform: none;
			letter-spacing: 0;
			@media(min-width: $screen-md){
				font-size: 58px;
				line-height: 0.92em;
				/*margin: 0 auto 82px;*/
			}
			@media(min-width: $screen-lg){
				/*margin: 0 auto 182px;*/
			}
			em {
				font-family: $accent-font-family;
				font-size: 1.276em;
			}
		}
		p {
			img {
				display: inline-block;
			}
			.btn {
				margin: 45px 30px 0;
			}
		}
	}
}
.homepage-cnt {
	@extend %clearfix;
	@extend %wrapper;
	max-width: 1040px;
	padding-top: 50px;
	@media(min-width: $screen-md){
		padding-top: 80px;
		padding-bottom: 38px;
	}
}
.homepage-inc {
	@include make-columns(12,3,3,3,0,0);
	h4, h2 {
		margin: 3px 0;
	}
	h2 {
		@extend .h1;
		letter-spacing: 0.15em;
	}
	hr {
		margin: 12px 3px;
	}
}
.homepage-text {
	@include make-columns(12,9,9,9,0,0);
}
.homepage-slider {
	@extend %wrapper;
	max-width: 1380px;
	margin-bottom: 67px;
	.slick-slide {
		margin: 3px;
	}
}
.home .spotlight-blocks {
	h2 {
		font-size: 42px;
		max-width: 250px;
		text-align: center;
		margin: 0 auto;
	}
	hr {
		margin: 12px auto;
		width: 70px;
	}
}
.homepage-blocks {
	@extend %spotlight-blocks;
	@media(min-width: $screen-sm){
		.spotlight-block {
			width: calc(60% - 5px);
			margin: 5px 5px 5px 0;
		}
		a:first-child .spotlight-block {
			width: calc(40% - 10px);
			margin: 5px;
		}
	}
}