/* 
  Required CSS
  you may change the name of the class
  (remember changing it in the js file too)
*/

/* for unsupported browsers */
.placeholder { color: #aaa }


/* for modern browsers */

/* webkit */
::-webkit-input-placeholder { color: #aaa }
/* firefox 4+ */
:-moz-placeholder { color: #aaa }
