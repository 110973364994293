//**********************************//
//   Default Locationsv2 Styles		//
//**********************************//

/* Global Locations styles */
.location-body {
	ul {
		padding-left: 0;
		margin: 40px 0;
		@media (min-width: $screen-md) {margin: 0;}
		li {
			list-style-type: none;
			// color: black;
			// &:nth-of-type(1) { // disabled until confirm with client
			// 	font-weight: bold;
			// }
		}
	}
}
/* Overview styles */
#location_categories {
	.location_cat {
		@extend %blur-hover;
		position: relative;
		margin: 4px;
		@media (min-width: $screen-md){
			float: left;
			width: calc(25% - 16px);
		}
		img {
			width: 100%;
		}
		.location_cat-info {
			@include abs-pos(0,0,0,0);
			@extend %clearfix;
			display: block;
			.location_cat-info-wrap {
				display: block;
				
				margin: 20% 15px;
				text-align: center;
				color: $light;
				@media (min-width: $screen-md){
					// width: 60%;
					margin: 25% auto 0;
				}
				@media (min-width: $screen-lg){
					margin: 30% auto 0;
				}
			}
			h2 {
				color: $light;
				@media (max-width: $screen-md){
					margin: 0;
				}
			}
		}
	}
}

/* Category styles */
.location_categories {
	h1 {
		font-size: 1.625em;
		text-transform: unset;
		color: $text-color;
		font-weight: bold;
		@media (max-width: $screen-xs-max) { font-size: 24px; }
	}
	.location-body {
		@media (min-width: $screen-md) {
			display: flex;
			flex-wrap: wrap;
		}
		div {
			@media (min-width: $screen-md) {
				flex: 1 1 calc(33%);
				padding: 0 10px;
				margin-bottom: 80px;
			}
			&:first-child {margin: 0;}
			// img {height: 320px; width: auto !important;}
			p {margin: 0;}
			iframe {margin: 0; height: 234px;}
			h3 {
				font-size: 1.325em;
				color: $text-color;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 20px;
				font-family: $font-family-base;
				@media (max-width: $screen-xs-max) { font-size: 20px; }
			}
		}
		table {
			tbody tr td:nth-of-type(1) {
				font-weight: bold;
				width: 60%;
			}
			&:before {
				display: block;
				content: 'Hours:';
				padding-bottom: 10px;
			}
		} 
	}

	#category-locations {
		margin: 40px 0;
		@media (min-width: $screen-md) {
			margin: 0;
		}
		.location {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			position: relative;
			&:after {
				content: '';
				width: 75%;
				height: 2px;
				display: block;
				position: absolute;
				background-color: $brand-accent;
				bottom: 0;
				right: 0;
				left: 12.5%;
			}
			&:last-of-type {
				&:after { content: unset; display: none; }
			}
			@media (min-width: $screen-sm){
				&:after { content: unset; }
				&:not(:nth-of-type(2n)) {
					&:after {
						content: '';
						width: 2px;
						height: auto;
						display: block;
						position: absolute;
						background-color: $brand-accent;
						top: 10px;
						bottom: 10px;
						right: 0;
						left: unset;
					}
				}
			}
			@media (min-width: $screen-md) {
				margin: 0;
				&:after { content: unset; }
				&:not(:nth-of-type(4n)) {
					&:after {
						content: '';
						width: 2px;
						height: auto;
						display: block;
						position: absolute;
						background-color: $brand-accent;
						top: 10px;
						bottom: 10px;
						right: 0;
						left: unset;
					}
				}
			}
			p {
				text-align: center;
				padding: 0 10px;
				text-transform: uppercase;
				&.location-link {
					// position: relative;
					margin: 10px auto;
					display: flex;
    				flex-direction: column;
    				justify-content: center;
    				align-items: center;
					a {
						display: block;
						&:after {
							position: absolute;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							z-index: 999;
							content: '';
						}
					}
				}
				&:not(.location-link){
					font-size: 12px;
					margin-top: 0;
					color: $brand-accent;
				}
			}
		}
	}

	#category-reviews {
		margin-top: 60px;
		.reviews-left {
			padding: 10px;
			padding-top: 0;
			h2 {
				text-transform: uppercase;
				margin-bottom: 40px;
				span {
					text-transform: lowercase;
					font-family: "Alex Brush",cursive;

				}
			}
			.testimonial-wrapper {
				padding: 10px;
				padding-bottom: 40px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: column-reverse;
				@media (min-width: $screen-md) {
					flex-direction: row;
				}
				.testimonial-content {
					padding-right: 20px;
					h3 {  font-size: 1.5em; }
					@media (max-width: $screen-md) {
						margin-top: 20px;
					}
				}
				@media (min-width: $screen-md) {
					.testimonial-image, .testimonial-content {
						flex: 0 1 50%;
					}
				}
				p {
					flex: 0 1 100%;
				}
			}
		}
	}
}

hr.full-hr {
	width: 98%;
	margin-top: 60px;
	border-top: 2px solid $brand-accent;
}

/* Detail styles */
.locationsv2_detail {
	#location_details, #location_address {
		width: 100% !important;
	}

	.location_address_left {
		min-height: 400px;
		i { color: $brand-accent; margin-right: 10px; }
	}

	.location_address_right iframe {
		margin: 0;
	}
	.custom-link-wrapper {
		
	}
	.custom-links {
		padding: 60px 0 0;
		@media (min-width: $screen-md) {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
		.custom-link {
			@extend .col-md-2;
			height: 140px;
			text-align: center;
			margin-bottom: 40px;
			display: block;
			// link icon before items
			&:before {
				display: block !important;
    			font-size: 50px !important;
    			padding-bottom: 14px;
    			position: relative;
    			top: 2px;
    			@extend .fa;
			}
			&.rules-link:before {
				content: "\f0ec";
 				@extend .fa-exchange;
 				transform: rotate(90deg) translateY(6px);
			}
			&.map-link { content: "\f278"; @extend .fa-map-o; }
			&.pricing-link { content: "\f155"; @extend .fa-usd; }
			&.document-link { content: "\f0f6"; @extend .fa-file-text-o; }
			// border after items
			&:after { content: unset; }
			&:not(:last-of-type):not(:nth-of-type(6n)) {
				&:after {
					@media (min-width: $screen-md) {
						content: '';
						width: 2px;
						height: auto;
						display: block;
						position: absolute;
						background-color: $brand-accent;
						top: 10px;
						bottom: 10px;
						right: 0;
						left: unset;
					}
				}
			}
		}
	}
	.product-wrapper {
		h2 { 
			text-align: center;
			font-family: "Alex Brush",cursive;
			margin: 40px 0 20px;
		}
		.products {
			border-top: 2px solid $brand-accent;
			@media (min-width: $screen-md) {
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
			}
			.product {
				margin: 20px 0;
				position: relative;
				img {
					width: 100%; height: auto;
				}
				p { text-align: center; text-transform: uppercase; }
				a {
					&:after {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						z-index: 999;
						content: "";
					}
				}
			}
			
		}
	}
	.action-form {
		h3 { margin: 40px 0 10px; }
		.formbuilderv2_form_field {
			width: 100% !important;
		}
	}
}
