// Extends

// Align items vertically in middle of container ( container must have a set height )
%middle-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
%vertical-align {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
// Obvious
%clearfix {
	&:before, &:after {
			content:" ";
			display: table;
	}
	&:after { clear:both; }
	& { *zoom:1; }
}
// Remove all margin and padding
%nospace {
	margin: 0;
	padding: 0;
}
// Remove all list styles
%clearlist {
	padding-left: 0;
	li {
		background-image: none;
		list-style-image: none;
		list-style-type: none;
		padding-left: 0;
		&:before { content: none; }
	}
}
// Transition states
%default-trans { transition: all .3s ease-in-out; }
%slow-trans { transition: all .5s ease-in-out; }
%fast-trans { transition: all .1s ease-in-out; }

 // For use with positioning psuedo elements ( :before and :after )
%psuedo-display {
	display: block;
	content: '';
	position: absolute;
}
// Include to use font-awesome icons as psuedo elements, bg's etc..
%font-awesome {
	display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
}
// Remove top margin from first element
%no-top {
	> *:first-child { margin-top: 0!important; }
}
// Remove bottom margin from last element
%no-bottom {
	> *:last-child { margin-bottom: 0!important; }
}
// No spacing for p tag
%clear-p {
	p {
		margin: 0;
		padding: 0; 
	}
}
// page-content styled p
%style-p {
	p {
		letter-spacing: 0.02em;
		line-height: 1.65em;
		margin: 25px 0;
	}
}

/* JBC's Extends for Quiring Monuments */
%wrapper {
	width: auto;
    max-width: 1200px;
    margin: 0 auto;
    @media(max-width: $screen-lg){
    	padding-right: 15px;
    	padding-left: 15px;
    }
}
%blur-hover {
	overflow: hidden;

	> img {
		transform: scale(1.1);
	}
	 
	&:hover > img {
		-webkit-filter: blur(2px);
	}
}
%centered-line {
    overflow: hidden;
    text-align: center;
	&:before,
	&:after {
	    background-color: $border-gray;
	    content: "";
	    display: inline-block;
	    height: 1px;
	    position: relative;
	    vertical-align: 45%;
	    width: 50%;
	}
	&:before {
	    right: 0.5em;
	    margin-left: -50%;
	}
	&:after {
	    left: 0.5em;
	    margin-right: -50%;
	}
}
%fadein {
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}
@keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}