.bct ul li {
	display: inline-block;
	background: none;
	padding: 0 0 0 3px;
}

.bct ul li::before {
	content: $bct-divider;
}

.bct ul li:first-child::before {
	content: none;
}
